import { AsyncPipe, DatePipe, JsonPipe, NgClass, NgFor, NgIf, NgStyle, SlicePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  inject
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MILISECONDS_PER_DAY } from '@ih/constants';
import { ContentTypes, DayOfWeek } from '@ih/enums';
import { BaseClientConfig, ContentItem, ContentRequest } from '@ih/interfaces';
import { ClipboardService, ConfigService, ContentService, LazySnackBarService } from '@ih/services';
import { getAppOriginFromConfig, getDaysInMonth } from '@ih/utilities';
import { isSameDay } from 'date-fns';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { ContentCardGhostComponent } from '../content-card-ghost/content-card-ghost.component';
import { ContentCardComponent } from '../content-card/content-card.component';
import { ItemWrapperComponent } from '../virtual-masonry/item-wrapper.component';
import { VirtualMasonryComponent } from '../virtual-masonry/virtual-masonry.component';

const MonthTypes = ['previous', 'current', 'next'] as const;
// MonthType is of 'previous' | 'current' | 'next'
declare type MonthType = (typeof MonthTypes)[number];

interface CalendarEvent extends ContentItem<Date> {
  startsOnThisDate: boolean;
  hovering: boolean;
  width: number;
  startsOnWeek: boolean;
  endsOnWeek: boolean;
  colorIndex: number;
}
interface Day {
  date: Date;
  dayOfMonth: number;
  isWeekend: boolean;
  month: MonthType;
  events: CalendarEvent[];
  estimatedHeight: number;
  isToday?: boolean;
}

@Component({
  standalone: true,
  selector: 'ih-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    DatePipe,
    JsonPipe,
    NgClass,
    NgFor,
    NgIf,
    NgStyle,
    RouterModule,
    SlicePipe,

    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,

    ContentCardComponent,
    ContentCardGhostComponent,
    ItemWrapperComponent,
    VirtualMasonryComponent
  ]
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class.ih-calendar') hostClass = true;
  @ViewChildren('dayContainer') dayContainers!: QueryList<ElementRef>;
  @ViewChild('monthCalendar') monthCalendar!: MatCalendar<Date>;
  @ViewChild('monthMenuTrigger') monthMenuTrigger!: MatMenuTrigger;

  private content = inject(ContentService);
  private registry = inject(MatIconRegistry);
  private sanitizer = inject(DomSanitizer);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private config = inject(ConfigService<BaseClientConfig>);
  private clipboard = inject(ClipboardService);
  private snackbar = inject(LazySnackBarService);

  @Input() get month(): number {
    return (this.selectedDate$.value ?? new Date()).getMonth();
  }

  set month(value: number) {
    const date = this.selectedDate$.value ?? new Date();
    date.setMonth(value);
    this.selectedDate$.next(date);
  }

  @Input() get year(): number {
    return (this.selectedDate$.value ?? new Date()).getFullYear();
  }

  set year(value: number) {
    const date = this.selectedDate$.value ?? new Date();
    date.setFullYear(value);
    this.selectedDate$.next(date);
  }

  private destroy$ = new Subject<void>();
  private contentRequest = { contentType: ContentTypes.Event, isTypeFeed: true } as ContentRequest;
  private obs!: IntersectionObserver;
  private selectedDate$ = new BehaviorSubject<Date | null>(null);
  private scrollTargetDate: Date | null = null;
  private ignoreScroll = false;
  // The maximum number of colors to use for events
  private colorIndexMax = 18;
  private initialized = false;
  // The height of the calendar header
  private calendarHeight = 96;
  private dayItemGap = 8;
  private dayVisibleMap = new Map<HTMLElement, boolean>();
  // The actual height of date header
  private dateHeaderHeight = 68;
  // The margin between events
  private dayMargin = 8;
  // the height of the card with an image
  private cardWithImage = 404;
  // the height of the card without an image
  private cardWithoutImage = 160;
  private boxShadow = 1;

  eventHoverArrayMap: { [key: number]: CalendarEvent[] } = {};

  dayOfMonth$ = this.selectedDate$.pipe(map((date) => (date ?? new Date()).getDate()));
  noEvents$ = new BehaviorSubject<boolean>(false);

  private userToggled = false;
  showCalendar$ = new BehaviorSubject<boolean>(true);
  dayOfMonthHover$ = new BehaviorSubject<{ dayOfMonth: number; month: MonthType } | null>(null);
  busy$ = new BehaviorSubject<boolean>(false);

  events$ = this.content
    .getContent$(this.contentRequest)
    .pipe(
      map((events) =>
        [...events].sort(
          (a, b) =>
            a.eventStartDate!.getTime() - b.eventStartDate!.getTime() ||
            a.eventEndDate!.getTime() - b.eventEndDate!.getTime()
        )
      )
    );

  calendarOptions$ = combineLatest([this.selectedDate$, this.events$]).pipe(
    filter(([selectedDate, events]) => !!selectedDate && !!events),
    map(
      ([selectedDate, events]) =>
        [selectedDate!.getFullYear(), selectedDate!.getMonth(), events] as [number, number, CalendarEvent[]]
    ),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    tap(([currentYear, currentMonth]) => {
      // check if the events have changed
      this.checkForUpdates(currentMonth, currentYear);
    }),
    map(([currentYear, currentMonth, events]) => {
      // Assign color indexes to events
      events = this.setColorIndexes(events);

      // Initialize firstDayOfWeek, weeks and days, monthIndex, and daysInMonth
      const firstDayOfWeek = new Date(currentYear, currentMonth, 1).getDay();
      const weeks: Day[][] = [];
      const days: Day[] = [];

      // Initialize today's date and noEvents flag
      const today = new Date();
      let noEvents = true;

      let monthIndex = currentMonth;
      let dayOfMonth = 1;
      let daysInMonth = getDaysInMonth(monthIndex, currentYear);

      // Generate weeks and days for the calendar
      for (let weekIndex = 0; weekIndex < 6; weekIndex++) {
        const week: Day[] = [];
        weeks.push(week);

        for (let dayOfWeekIndex = DayOfWeek.Sunday; dayOfWeekIndex <= DayOfWeek.Saturday; dayOfWeekIndex++) {
          if (weekIndex === 0 && dayOfWeekIndex < firstDayOfWeek) {
            // If we're in the first week and we haven't reached the first day of the month,
            // we need to calculate the days from the end of the previous month
            monthIndex = currentMonth - 1;
            daysInMonth = getDaysInMonth(monthIndex, currentYear);
            dayOfMonth = daysInMonth - (firstDayOfWeek - dayOfWeekIndex) + 1;
          }

          const dayStart = new Date(currentYear, monthIndex, dayOfMonth, 0, 0, 0, 0);
          const dayEnd = new Date(currentYear, monthIndex, dayOfMonth, 23, 59, 59, 999);
          let eventsForDay = this.getEventsForDay(events, dayStart, dayEnd);
          eventsForDay = this.sortEventsByWidth(eventsForDay);

          if (eventsForDay.length > 0) {
            noEvents = false;
          }

          // calculate the estimated height of the day container
          const estimatedHeight =
            this.dateHeaderHeight +
            eventsForDay.reduce(
              (sum, cur, idx) =>
                sum +
                (cur.mediaType === 'image' ? this.cardWithImage : this.cardWithoutImage) +
                (idx < eventsForDay.length - 1 ? this.dayItemGap : 0),
              0
            ) +
            this.dayMargin +
            this.boxShadow;

          const monthType = MonthTypes[monthIndex - currentMonth + 1];
          const dayData: Day = {
            date: new Date(currentYear, monthIndex, dayOfMonth),
            dayOfMonth,
            isWeekend: dayOfWeekIndex === DayOfWeek.Sunday || dayOfWeekIndex === DayOfWeek.Saturday,
            month: monthType,
            events: eventsForDay,
            estimatedHeight,
            isToday: dayOfMonth === today.getDate() && monthIndex === today.getMonth()
          };
          week.push(dayData);

          if (monthType === 'current') {
            days.push(dayData);
          }

          dayOfMonth++;
          // if we've reached the end of the monthIndex, reset the day of the month and increment the month index
          if (dayOfMonth > daysInMonth) {
            monthIndex++;
            dayOfMonth = 1;
            daysInMonth = getDaysInMonth(monthIndex, currentYear);
          }
        }
      }

      this.noEvents$.next(noEvents);

      // If a scroll target date is set, scroll to it
      if (this.scrollTargetDate) {
        requestAnimationFrame(() => {
          this.scrollToDate(this.scrollTargetDate!);
        });
      }

      // Return generated calendar data
      return {
        days,
        weeks,
        currentMonth:
          new Date(currentYear, currentMonth, 1).toLocaleString('default', { month: 'short' }) + ' ' + currentYear
      };
    }),
    shareReplay(1)
  );

  constructor() {}

  ngOnInit(): void {
    // get the selected date from the query params if they are set
    // use startWith to get the initial value from the snapshot
    this.route.queryParams
      .pipe(startWith(this.route.snapshot.queryParams), takeUntil(this.destroy$))
      .subscribe((params) => {
        if (params['month'] && params['year'] && params['day']) {
          // skip initialization so the current day doesn't get selected
          if (!this.initialized) {
            this.initialized = true;
            this.setCalendarVisible(false);
          }
          // scroll to the selected date
          const date = new Date(parseInt(params['year']), parseInt(params['month']) - 1, parseInt(params['day']));
          this.selectDate(date);
          requestAnimationFrame(() => {
            this.scrollToDate(date);
          });
          return;
        }

        this.selectDate(new Date());
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngAfterViewInit(): void {
    this.scrollToDate(this.selectedDate$.value!);

    this.dayContainers.changes.pipe(takeUntil(this.destroy$)).subscribe((dayContainers) => {
      if (!this.obs) {
        return;
      }

      this.initObserver();
      this.scrollToDate(this.selectedDate$.value!);
    });

    // initialize the observer on startup
    this.initObserver();
  }

  private checkForUpdates(currentMonth: number, currentYear: number): void {
    // set or change the month and year in the content request
    // using month + 1 because the month is 0 indexed in javascript and the server is expecting a 1 indexed month
    this.contentRequest = {
      ...this.contentRequest,
      month: currentMonth + 1,
      year: currentYear
    };

    this.busy$.next(true);
    // load events for the selected month and year
    this.content.loadMore$(this.contentRequest).subscribe(() => {
      this.busy$.next(false);
      if (!this.initialized) {
        setTimeout(() => {
          this.setCalendarVisible(false);
          // select the current date
          this.selectDate(this.selectedDate$.value!);
          this.initialized = true;
        }, 100);
      }
    });
  }

  private setColorIndexes(events: CalendarEvent[]): CalendarEvent[] {
    // set the colorIndex for each event based on the contentId
    return events.map((event) => ({
      ...event,
      colorIndex: event.contentId % this.colorIndexMax
    }));
  }

  private getWeekStartAndEnd(dayStart: Date) {
    const currentYear = dayStart.getFullYear();
    const monthIndex = dayStart.getMonth();
    const dayOfMonth = dayStart.getDate();
    const dayOfWeekIndex = dayStart.getDay();

    const weekStart = new Date(currentYear, monthIndex, dayOfMonth - dayOfWeekIndex, 0, 0, 0, 0);
    const weekEnd = new Date(currentYear, monthIndex, dayOfMonth + (6 - dayOfWeekIndex), 23, 59, 59, 999);

    return { weekStart, weekEnd };
  }

  private eventOccursOnDay(event: CalendarEvent, dayStart: Date, dayEnd: Date): boolean {
    const eventStartDate = new Date(event.eventStartDate!);
    const eventEndDate = new Date(event.eventEndDate!);

    return (
      (eventStartDate >= dayStart && eventStartDate <= dayEnd) ||
      (eventEndDate >= dayStart && eventEndDate <= dayEnd) ||
      (eventStartDate <= dayStart && eventEndDate >= dayEnd)
    );
  }

  private calculateEventProperties(
    event: CalendarEvent,
    weekStart: Date,
    weekEnd: Date,
    dayStart: Date
  ): CalendarEvent {
    let eventStartsThisWeek = true;
    let eventEndsThisWeek = true;
    let eventStartDateThisWeek = new Date(event.eventStartDate!);
    let eventEndDateThisWeek = new Date(event.eventEndDate!);

    if (eventStartDateThisWeek < weekStart) {
      eventStartDateThisWeek = weekStart;
      eventStartsThisWeek = false;
    }
    if (eventEndDateThisWeek > weekEnd) {
      eventEndDateThisWeek = weekEnd;
      eventEndsThisWeek = false;
    }

    const daysSpanned = this.getDayCountBetweenDates(eventStartDateThisWeek, eventEndDateThisWeek);
    const dayOfWeekIndex = dayStart.getDay();
    const width =
      dayOfWeekIndex !== DayOfWeek.Sunday && !isSameDay(eventStartDateThisWeek, dayStart) ? 0 : Math.floor(daysSpanned);

    const startsOnThisDate = isSameDay(eventStartDateThisWeek, dayStart) || dayOfWeekIndex === DayOfWeek.Sunday;

    // clone the event so the next time this is set it will be a new object
    const newEvent = {
      ...event,
      width,
      startsOnWeek: eventStartsThisWeek,
      endsOnWeek: eventEndsThisWeek,
      startsOnThisDate
    };

    // add the event to the eventHoverArrayMap so we can update the hovering state of all events with the same contentId
    this.eventHoverArrayMap[newEvent.contentId] ??= [];
    this.eventHoverArrayMap[newEvent.contentId].push(newEvent);

    return newEvent;
  }

  private getEventsForDay(events: CalendarEvent[], dayStart: Date, dayEnd: Date): CalendarEvent[] {
    const { weekStart, weekEnd } = this.getWeekStartAndEnd(dayStart);

    return events
      .filter((event) => this.eventOccursOnDay(event, dayStart, dayEnd))
      .map((event) => this.calculateEventProperties(event, weekStart, weekEnd, dayStart));
  }

  private getDayCountBetweenDates(startDate: Date, endDate: Date): number {
    // calculate the number of days the event should cover for of the current week
    let daysSpanned = Math.ceil((endDate.getTime() - startDate.getTime()) / MILISECONDS_PER_DAY);
    // if the daysSpanned is less than 1, set it to 1
    // for example if the event starts and ends on the same day, or if it starts on the last day of the week
    if (daysSpanned < 1) {
      daysSpanned = 1;
    }

    return daysSpanned;
  }

  private sortEventsByWidth(events: CalendarEvent[]): CalendarEvent[] {
    return events.sort((eventA, eventB) => {
      const eventAWidth = eventA.width;
      const eventBWidth = eventB.width;

      if (eventAWidth === 0 && eventBWidth === 0) {
        return 0; // If both have a width of 0, don't sort
      }
      if (eventAWidth === 0) {
        return -1; // Make a come first if its width is 0
      }
      if (eventBWidth === 0) {
        return 1; // Make b come first if its width is 0
      }

      return eventBWidth - eventAWidth; // Otherwise, sort from widest to narrowest
    });
  }

  copyLink(date: Date): void {
    this.config.config$.pipe(take(1)).subscribe((config) => {
      // get the origin from the config including custom domain if applicable
      const origin = getAppOriginFromConfig(config);
      // get content item url
      const detailPath = `/events?month=${date.getMonth() + 1}&year=${date.getFullYear()}&day=${date.getDate()}`;
      // add comment id to url
      const link = `${origin}${detailPath}`;

      this.clipboard
        .copyTextToClipboard(link)
        .then(() => {
          this.snackbar.open('Link copied to clipboard');
        })
        .catch((err) => {
          if ('ontouchstart' in window) {
            this.snackbar.open('Long press to copy');
            return;
          }
          throw err;
        });
    });
  }

  monthSelected(date: Date): void {
    // combine the month and year from the selected date with the day from the selectedDate$ observable
    // this is so the selected date doesn't change when the month is changed
    const selectedDate = this.selectedDate$.value!;
    // if the selected month has less days than the current month, we need to update the selected date
    const daysInMonth = getDaysInMonth(date.getMonth(), date.getFullYear());
    selectedDate.setMonth(date.getMonth(), Math.min(selectedDate.getDate(), daysInMonth));
    selectedDate.setFullYear(date.getFullYear());
    this.selectDate(selectedDate);

    this.monthMenuTrigger.closeMenu();
  }

  showMonthMenu(): void {
    this.monthCalendar.currentView = 'multi-year';
    this.monthCalendar.activeDate = this.selectedDate$.value!;
    this.monthCalendar.selected = this.selectedDate$.value;
  }

  selectDate(date: Date) {
    this.selectedDate$.next(date);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        day: date.getDate()
      },
      queryParamsHandling: 'merge'
    });
  }

  dateClicked(date: Date) {
    this.setCalendarVisible(false);

    this.selectDate(date);
  }

  trackByEventFn(index: number, event: CalendarEvent): string {
    return event.contentId + (event.lastUpdated ? new Date(event.lastUpdated).toISOString() : '');
  }

  trackByDayFn(index: number, day: Day): string {
    return day.date.getTime() + day.events.map((event) => event.contentId).join('');
  }

  private previousScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  private totalScrollDistance = 0;
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (this.ignoreScroll) {
      return;
    }

    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    const scrollDifference = currentScrollPosition - this.previousScrollPosition;

    if (currentScrollPosition === 0) {
      return;
    }

    if (scrollDifference > 0) {
      this.totalScrollDistance += scrollDifference;

      if (this.totalScrollDistance >= 100) {
        // User is scrolling down
        if (this.showCalendar$.value && !this.userToggled) {
          this.setCalendarVisible(false);
        }
        // Reset the total scroll distance
        this.totalScrollDistance = 0;
      }
    }

    this.previousScrollPosition = currentScrollPosition;
  }

  private setCalendarVisible(showCalendar = false): void {
    this.showCalendar$.next(showCalendar);
  }

  onHoverEvent(event: CalendarEvent, hovering: boolean): void {
    this.eventHoverArrayMap[event.contentId].forEach((eventItem) => {
      eventItem.hovering = hovering;
    });
  }

  private scrollToDate(date: Date): void {
    // scroll the matching day container to the top of the viewport
    const dayContainer = this.dayContainers.find((item) => {
      return item.nativeElement.dataset.day === date.getDate().toString();
    });
    if (dayContainer) {
      this.scrollTargetDate = date;
      const topNavHeight = 56;
      window.scrollTo({
        top: dayContainer.nativeElement.offsetTop - this.calendarHeight - topNavHeight
      });

      // this flag is to tell intersection observer to ignore the fact that the container is being scrolled to
      // if we don't do this then the selected date will change as the container scrolls and headers become visible
      // can't use raf because we need intersection observer to run first
      setTimeout(() => {
        this.scrollTargetDate = null;
      });
    }
  }

  async eventClicked(clickEvent: Event | null, calendarEvent: CalendarEvent, dayOfMonth: number): Promise<void> {
    if (clickEvent) {
      clickEvent.preventDefault();
    }

    requestAnimationFrame(() => {
      const element = document.querySelector('.events-' + calendarEvent.contentId + '-' + dayOfMonth) as HTMLDivElement;

      if (element) {
        const topNavHeight = 56;
        const dateHeaderHeight = 68;
        const cardPadding = 8;
        const offsetPosition = element.offsetTop - this.calendarHeight - topNavHeight - dateHeaderHeight - cardPadding;

        window.scrollTo({
          top: offsetPosition
        });
      }
    });

    this.setCalendarVisible(false);
  }

  changeMonth(event: Event, offset: number): void {
    event.stopImmediatePropagation();
    // if the previous month has less days than the current month, we need to update the selected date
    const daysInMonth = getDaysInMonth(this.month + offset, this.year);
    this.selectDate(
      new Date(this.year, this.month + offset, Math.min(this.selectedDate$.value!.getDate(), daysInMonth))
    );
  }

  today(): void {
    this.selectDate(new Date());
  }

  toggleCalendar(): void {
    this.userToggled = true;
    this.setCalendarVisible(!this.showCalendar$.value);
  }

  initObserver(): void {
    if (this.obs) {
      this.dayVisibleMap.clear();
      this.obs.disconnect();
    }

    this.obs = new IntersectionObserver(
      (entries) => {
        if (this.scrollTargetDate) {
          return;
        }

        // use some so it stops at the first entry that is intersecting
        // check the entries in reverse order so we get the last one that is intersecting
        entries.forEach((entry) => {
          this.dayVisibleMap.set(entry.target as HTMLElement, entry.isIntersecting);
        });

        // get the first visible entry in the map
        const entry = Array.from(this.dayVisibleMap.entries()).find(([, isVisible]) => isVisible);
        if (entry) {
          const day = parseInt((entry[0] as HTMLElement).dataset['day']!, 10);
          this.selectedDate$.next(new Date(this.year, this.month, day));
        }
      },
      {
        // subtract the height of the calendar and the top nav from the root margin
        // so elements that are under the calendar header and top nav are NOT considered visible
        // 56 px is the height of the top nav, 8px is the margin between the calendar and the top nav
        rootMargin: `-${this.calendarHeight + 56 + 8}px 0px 0px 0px`
      }
    );

    this.dayContainers.forEach((container) => {
      this.dayVisibleMap.set(container.nativeElement, false);
      this.obs.observe(container.nativeElement);
    });
  }
}
