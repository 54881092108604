<ng-container *ngIf="post$ | async as post">
  <div class="photos" *ngIf="(post?.postPhotos ?? []).length > 0">
    @for (photo of post.postPhotos; track photo.postPhotoId){
    <div class="photo-container">
      <a (click)="showPhotoLightbox(post.postPhotos!, $index)">
        <ih-image [aspectRatio]="1.0" [imageInfo]="photo.imageInfo" [cropToFit]="true"></ih-image>
      </a>
    </div>
  }
  </div>
</ng-container>
