import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type SliderEditorComponent } from './slider-editor/slider-editor.component';

@Injectable({
  providedIn: 'root'
})
export class SliderEditorDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  async open(sliderId: string): Promise<MatDialogRef<SliderEditorComponent>> {
    const sliderEditorDialogComponent = await import('./slider-editor/slider-editor.component').then(
      (m) => m.SliderEditorComponent
    );
    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(sliderEditorDialogComponent, {
      panelClass: ['slider-editor-dialog', 'dialog-no-padding', 'dialog-fullscreen'],
      width: undefined,
      height: undefined,
      maxWidth: undefined,
      maxHeight: undefined,
      disableClose: true,
      data: sliderId
    });
  }
}
