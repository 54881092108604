import { AsyncPipe, CurrencyPipe, DatePipe, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnimatedSaveButtonComponent } from '@ih/animated-save-button';
import { BasicDialogComponent } from '@ih/basic-dialog';
import { Attendee } from '@ih/interfaces';
import { LazySnackBarService } from '@ih/services';
import { catchError, map, tap } from 'rxjs/operators';

interface AttendeeListItem extends Attendee {
  total: number;
}

@Component({
  selector: 'ih-attendees-dialog',
  standalone: true,
  imports: [
    AsyncPipe,
    CurrencyPipe,
    DatePipe,
    NgIf,

    FormsModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatTableModule,
    MatTooltipModule,

    AnimatedSaveButtonComponent,
    BasicDialogComponent
  ],
  templateUrl: './attendees-dialog.component.html',
  styleUrl: './attendees-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttendeesDialogComponent {
  private dialogRef = inject(MatDialogRef);
  private http = inject(HttpClient);
  private lazySnackbar = inject(LazySnackBarService);
  public eventId = inject<number>(MAT_DIALOG_DATA);
  private cd = inject(ChangeDetectorRef);

  displayedColumns = ['name', 'quantity', 'price', 'total'];

  browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  saving = signal<boolean>(false);
  loading = signal<boolean>(true);

  attendees = toSignal(
    this.http.get<AttendeeListItem[]>(`/api/events/${this.eventId}/attendees`).pipe(
      map((attendees) => {
        this.loading.set(false);
        return attendees.map((attendee) => {
          attendee.total =
            attendee.packages.reduce((total, p) => total + p.price, 0) +
            attendee.addons?.reduce((total, a) => total + a.price, 0);
          return attendee;
        });
      })
    )
  );

  cancel(): void {
    this.dialogRef.close();
  }

  save(attendee: AttendeeListItem): void {
    this.http.put('/api/events/attendees/' + attendee.event_user_id, attendee).subscribe(() => {
      attendee.updating = false;
      this.lazySnackbar.open('Attendee updated');
    });
  }

  refund(attendee: AttendeeListItem): void {
    this.http
      .post('/api/financial/tickets/refund/' + attendee.event_user_id, {})
      .pipe(
        tap(() => {
          this.lazySnackbar.open('Payment successfully refunded');
          attendee.refunded = true;
          this.cd.markForCheck();
        }),
        catchError((error) => {
          this.lazySnackbar.open('Oh no! There was a problem refunding this payment.');
          throw new Error(error);
        })
      )
      .subscribe();
  }
}
