import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';

@Injectable({
  providedIn: 'root'
})
export class LightboxDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(options: any): Promise<MatDialogRef<unknown>> {
    const lightboxDialogComponent: ComponentType<unknown> = await import('./lightbox-dialog.component').then(
      (m) => m.LightboxDialogComponent
    );

    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(lightboxDialogComponent, {
      data: options,
      panelClass: ['lightbox-dialog', 'responsive-dialog', 'dialog-no-padding'],
      width: '100%',
      height: '100%',
      minWidth: '100%',
      minHeight: '100%'
    });
  }
}
