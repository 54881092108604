import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditPageService {
  isEditing = signal<boolean>(false);

  isSaving = signal<boolean>(false);

  private _save$ = new Subject<void>();
  save$ = this._save$.asObservable();

  async save(): Promise<void> {
    this.isSaving.set(true);
    this.isEditing.set(false);

    this._save$.next();
  }

  reset(): void {
    this.isSaving.set(false);
    this.isEditing.set(false);
  }

  async setIsSaving(saving: boolean): Promise<void> {
    this.isSaving.set(saving);
  }

  async setEditing(editing: boolean): Promise<void> {
    this.isSaving.set(false);
    this.isEditing.set(editing);
  }
}
