import { Pipe, PipeTransform } from '@angular/core';
import { durationTransform } from './pipes';

@Pipe({
  standalone: true,
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(value: number): unknown {
    return durationTransform(value);
  }
}
