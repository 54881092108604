import { HttpClient } from '@angular/common/http';
import { Directive, Input, OnInit, inject } from '@angular/core';
import { ChannelDialogListItem, ChannelDialogService } from '@ih/dialogs';
import { ContentTypes, FilterChipType } from '@ih/enums';
import { AppConfig, ListFilterItemValue, ListQuery, PostType } from '@ih/interfaces';
import { ConfigService } from '@ih/services';
import { firstValueFrom, take } from 'rxjs';
import { FilterChipsComponent } from './filter-chips.component';

@Directive({
  selector: '[ihAllContentFilterChips]',
  standalone: true
})
export class AllContentFilterChipsDirective implements OnInit {
  private filterChips = inject(FilterChipsComponent);
  private channelDialog = inject(ChannelDialogService);
  private http = inject(HttpClient);
  private config = inject(ConfigService<AppConfig>);

  @Input() landingPage = false;

  ngOnInit(): void {
    this.filterChips.types = [
      {
        name: 'Title',
        id: 'title',
        type: FilterChipType.Text,
        inputLabel: 'Contains',
        queryParam: 'query'
      },
      {
        name: 'Content type',
        id: 'contentType',
        type: FilterChipType.Select,
        queryParam: 'contentType',
        options: [
          {
            name: 'Slider',
            value: ContentTypes.Slider
          },
          {
            name: 'Page',
            value: ContentTypes.Page
          },
          {
            name: 'Post',
            value: ContentTypes.Post
          },
          {
            name: 'Event',
            value: ContentTypes.Event
          }
        ]
      },
      {
        name: 'Status',
        id: 'status',
        type: FilterChipType.Select,
        queryParam: 'filterType',
        options: [
          {
            name: 'Published',
            value: 'published'
          },
          {
            name: 'Draft',
            value: 'unpublished'
          },
          {
            name: 'Scheduled',
            value: 'scheduled'
          },
          {
            name: 'Expired',
            value: 'expired'
          },
          {
            name: 'Archived',
            value: 'archived'
          }
        ]
      },
      {
        name: 'Channel',
        id: 'channel',
        type: FilterChipType.Select,
        queryParam: 'channelIds',
        multiple: true,
        options: undefined,
        onOpenOptionsDialog: () => Promise.resolve([] as ListFilterItemValue[])
      },
      {
        name: 'Post label',
        id: 'postTypeId',
        type: FilterChipType.Select,
        queryParam: 'postTypeId',
        options: undefined
      },
      {
        name: 'Module',
        id: 'module',
        type: FilterChipType.Select,
        queryParam: 'moduleIds',
        multiple: true,
        options: undefined
      }
    ];

    this.config.availableModules$.pipe(take(1)).subscribe((modules) => {
      let moduleFilter = this.filterChips.types.find((t) => t.id === 'module')!;
      moduleFilter = {
        ...moduleFilter,
        options: modules
          .sort((a, b) => a.key - b.key)
          .map<ListFilterItemValue>((module) => ({
            name: module.title,
            value: module.key.toString()
          }))
      };

      this.filterChips.types = [...this.filterChips.types.filter((t) => t.id !== 'module'), moduleFilter];
    });

    this.http
      .get<ChannelDialogListItem[] | ListQuery<ChannelDialogListItem>>('/api/channels', {
        params: {
          context: 'contentFilter',
          includeEveryone: true,
          limit: '9001'
        }
      })
      .subscribe((resp) => {
        let channels: ChannelDialogListItem[] = resp as ChannelDialogListItem[];
        if ((resp as ListQuery<ChannelDialogListItem>).items) {
          channels = (resp as ListQuery<ChannelDialogListItem>).items;
        }

        // find the channel filter
        let channelFilter = this.filterChips.types.find((t) => t.id === 'channel')!;
        channelFilter = {
          ...channelFilter,
          options: channels.map((channel) => {
            return {
              name: channel.name,
              value: channel.channelId.toString()
            } as ListFilterItemValue;
          }),
          onOpenOptionsDialog: async (query: ListFilterItemValue[]) => {
            const dialog = await this.channelDialog.open(
              {
                dialogTitle: 'Select Channels',
                selectedChannels: query
                  ? channels.filter((channel) => query.map((c) => +(c.value as string)).includes(channel.channelId))
                  : [],
                context: 'contentFilter',
                isContentCreator: true,
                includeEveryone: true
              },
              false
            );

            const pickedChannels = await firstValueFrom(dialog.afterClosed());
            return (
              pickedChannels?.map((channel) => {
                return {
                  name: channel.name,
                  value: channel.channelId.toString()
                } as ListFilterItemValue;
              }) ?? []
            );
          }
        };

        this.filterChips.types = [...this.filterChips.types.filter((t) => t.id !== 'channel'), channelFilter];
      });

    if (!this.landingPage) {
      this.http.get<PostType[]>('/api/posts/postTypes?context=contentFilter').subscribe((postTypes) => {
        postTypes ??= [];

        let postTypesFilter = this.filterChips.types.find((t) => t.id === 'postTypeId')!;
        postTypesFilter = {
          ...postTypesFilter,
          options: postTypes.map((postType) => ({
            name: postType.name ?? '',
            value: postType.postTypeId!.toString()
          }))
        };

        this.filterChips.types = [...this.filterChips.types.filter((t) => t.id !== 'postTypeId'), postTypesFilter];
      });
    }
  }
}
