import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ImageComponent } from '@ih/image';
import { Photo, PostDetail } from '@ih/interfaces';
import { BehaviorSubject } from 'rxjs';
import { LightboxDialogService } from '../lightbox-dialog/lightbox-dialog.service';

@Component({
  selector: 'ih-post-photo-detail',
  templateUrl: './post-photo-detail.component.html',
  styleUrls: ['./post-photo-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgFor, NgIf, ImageComponent]
})
export class PostPhotoDetailComponent {
  @HostBinding('class.ih-post-photo-detail') hostClass = true;

  // eslint-disable-next-line accessor-pairs
  @Input()
  set post(post: PostDetail) {
    this.post$.next(post);
  }

  post$ = new BehaviorSubject<PostDetail | undefined>(undefined);

  constructor(private lightboxDialog: LightboxDialogService) {}

  photoOnLoad(index: number): void {
    window.document.querySelector('.photo-container:nth-child(' + (index + 1) + ') img')!.classList.add('loaded');
  }

  showPhotoLightbox(photos: Photo[], startIndex: any): void {
    this.lightboxDialog.open({
      photos,
      index: startIndex
    });
  }
}
