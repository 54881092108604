import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BasicDialogComponent } from '@ih/basic-dialog';
import { ImageComponent } from '@ih/image';
import { UserViewPipe } from '@ih/pipes';
import { LazySnackBarService } from '@ih/services';
import { BehaviorSubject, catchError, debounceTime, map, of } from 'rxjs';

export interface ContentViewersDialogOptions {
  contentType: string;
  contentId: number;
  dialogTitle: string;
}

@Component({
  standalone: true,
  selector: 'ih-content-viewers-dialog',
  templateUrl: './content-viewers-dialog.component.html',
  styleUrls: ['./content-viewers-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    ReactiveFormsModule,

    BasicDialogComponent,
    ImageComponent,
    UserViewPipe
  ]
})
export class ContentViewersDialogComponent {
  private http = inject(HttpClient);
  private snackbar = inject(LazySnackBarService);
  private matDialogRef = inject(MatDialogRef<ContentViewersDialogComponent>);
  loading$ = new BehaviorSubject<boolean>(true);
  public options: ContentViewersDialogOptions = inject(MAT_DIALOG_DATA);

  predicate = { field: 'user.fullName', reverse: false };
  userQuery = new FormControl('');
  columnsToDisplay = ['user', 'count'];
  userViews$ = this.http.get<any>(`/api/${this.options.contentType}/${this.options.contentId}/views`).pipe(
    debounceTime(100),
    map((data) => {
      this.loading$.next(false);
      return data;
    }),
    catchError((err) => {
      this.loading$.next(false);
      console.error(err);
      this.snackbar.open('There was a problem getting views for that content. Please try again.');
      return of([]);
    })
  );

  cancel(): void {
    this.matDialogRef.close();
  }

  changePredicate(event: any) {
    this.predicate = {
      field: event.active,
      reverse: event.direction !== 'asc'
    };
  }
}
