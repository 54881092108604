import { Pipe, PipeTransform } from '@angular/core';
import { UserView } from '@ih/interfaces';

@Pipe({
  standalone: true,
  name: 'filterUserViews'
})
export class UserViewPipe implements PipeTransform {
  transform(values: UserView[], query: string, predicate: { field: string; reverse: boolean }): UserView[] {
    if (!values) {
      return [];
    }
    const result = values
      .filter((v) => {
        return (
          !query ||
          (v.user?.fullName?.toLocaleLowerCase() || '').includes(query.toLocaleLowerCase()) ||
          (v.user?.email?.toLocaleLowerCase() || '').includes(query.toLocaleLowerCase())
        );
      })
      .sort((a, b) => {
        if (predicate.field === 'user.fullName') {
          if ((a.user?.fullName ?? '') > (b.user?.fullName ?? '')) return predicate.reverse ? 1 : -1;
          if ((a.user?.fullName ?? '') < (b.user?.fullName ?? '')) return predicate.reverse ? -1 : 1;
          return 0;
        } else {
          if (a.count > b.count) return predicate.reverse ? 1 : -1;
          if (a.count < b.count) return predicate.reverse ? -1 : 1;
          return 0;
        }
      });
    return result;
  }
}
