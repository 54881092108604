<div class="card">
  <div class="card-image responsive pulse"></div>
  <div class="footer">
    <div class="footer-text">
      <div class="title pulse"></div>
      <div class="date pulse"></div>
    </div>
    <div class="icons">
      <div class="icon pulse"></div>
    </div>
  </div>
</div>
