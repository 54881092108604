import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CopyDirective, SocialShareDirective, TrackClickDirective } from '@ih/directives';
import { AppConfig } from '@ih/interfaces';
import { ConfigService, ScriptService } from '@ih/services';
import { tweet } from '@ih/utilities';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  standalone: true,
  selector: 'ih-share-controls',
  templateUrl: './share-controls.component.html',
  styleUrls: ['./share-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    CopyDirective,
    SocialShareDirective,
    TrackClickDirective
  ]
})
export class ShareControlsComponent implements OnInit {
  @HostBinding('class.ih-share-controls') hostClass = true;

  private config = inject(ConfigService<AppConfig>);
  private script = inject(ScriptService);

  isTemplate = toSignal(this.config.config$.pipe(map((config) => config.isTemplate)));

  url$ = new BehaviorSubject<string | null>(null);

  @Input()
  get url(): string | null {
    return this.url$.value;
  }

  set url(v: string) {
    this.url$.next(v);
  }

  title$ = new BehaviorSubject<string | null>(null);

  @Input()
  get title(): string | null {
    return this.title$.value;
  }

  set title(v: string) {
    this.title$.next(v);
  }

  canWebShare = navigator.share;

  constructor() {}

  ngOnInit(): void {
    this.script.loadSocial().subscribe();
  }

  facebookShare(): void {
    window.FB.ui(
      {
        method: 'share',
        href: this.url,
        display: 'iframe'
      },
      function (response: any) {
        // Debug response (optional)
        console.log(response);
      }
    );
  }

  twitterShare(): void {
    tweet(
      'https://twitter.com/intent/tweet?url=' +
        window.encodeURIComponent(this.url!) +
        '&text=' +
        window.encodeURIComponent(this.title!) +
        '%0A'
    );
  }

  webShare(): void {
    window.navigator
      .share({
        title: this.title!,
        url: this.url!
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  }
}
