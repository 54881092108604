<div class="scaffolding">
  <div class="slider-scaffold">
    <div class="slider-image-scaffold pulse"></div>
  </div>
  <div class="slider-scaffold">
    <div class="slider-image-scaffold pulse"></div>
  </div>
  <div class="slider-scaffold">
    <div class="slider-image-scaffold pulse"></div>
  </div>
</div>
