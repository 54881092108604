<div class="fx-layout-row">
  <button
    class="web-share"
    mat-icon-button
    *ngIf="canWebShare"
    (click)="webShare()"
    [disabled]="isTemplate()"
    trackClick="Share controls Webshare button clicked"
    [trackProperties]="{ url: this.url }"
    title="Share via link"
  >
    <mat-icon>
      <svg viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M18,16.08C17.24,16.08 16.56,16.38 16.04,16.85L8.91,12.7C8.96,12.47 9,12.24 9,12C9,11.76 8.96,11.53 8.91,11.3L15.96,7.19C16.5,7.69 17.21,8 18,8A3,3 0 0,0 21,5A3,3 0 0,0 18,2A3,3 0 0,0 15,5C15,5.24 15.04,5.47 15.09,5.7L8.04,9.81C7.5,9.31 6.79,9 6,9A3,3 0 0,0 3,12A3,3 0 0,0 6,15C6.79,15 7.5,14.69 8.04,14.19L15.16,18.34C15.11,18.55 15.08,18.77 15.08,19C15.08,20.61 16.39,21.91 18,21.91C19.61,21.91 20.92,20.61 20.92,19A2.92,2.92 0 0,0 18,16.08Z"
        />
      </svg>
    </mat-icon>
  </button>
  <button
    mat-icon-button
    class="facebook fx-hide-lt-xs"
    ihSocialShare="facebook"
    [url]="this.url!"
    trackClick="Share controls Facebook share button clicked"
    [trackProperties]="{ url: this.url }"
    title="Share on Facebook"
  >
    <mat-icon>
      <svg viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
        />
      </svg>
    </mat-icon>
  </button>

  <button
    mat-icon-button
    class="twitter fx-hide-lt-xs"
    ihSocialShare="twitter"
    [url]="this.url!"
    trackClick="Share controls Twitter share button clicked"
    [trackProperties]="{ url: this.url }"
    title="Share on Twitter"
  >
    <mat-icon>
      <svg viewBox="0 0 1200 1227" fill="none" style="width: 20px; height: 20px">
        <path
          d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
          fill="black"
        />
      </svg>
    </mat-icon>
  </button>

  <button
    mat-icon-button
    class="linkedin fx-hide-lt-xs"
    ihSocialShare="linkedin"
    [url]="this.url!"
    trackClick="Share controls LinkedIn share button clicked"
    [trackProperties]="{ url: this.url }"
    title="Share on LinkedIn"
  >
    <mat-icon>
      <svg viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M20,2h-16c-1.1,0-2,0.9-2,2v16c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2zM8,19h-3v-9h3V19zM6.5,8.8C5.5,8.8,4.7,8,4.7,7s0.8-1.8,1.8-1.8S8.3,6,8.3,7S7.5,8.8,6.5,8.8zM19,19h-3v-4c0-1.4-0.6-2-1.5-2c-1.1,0-1.5,0.8-1.5,2.2V19h-3v-9h2.9v1.1c0.5-0.7,1.4-1.3,2.6-1.3c2.3,0,3.5,1.1,3.5,3.7V19z"
        />
      </svg>
    </mat-icon>
  </button>

  <button
    mat-icon-button
    class="link"
    aria-label="Copy link"
    [copy]="this.url!"
    trackClick="Share controls Copy link button clicked"
    [trackProperties]="{ url: this.url }"
    title="Copy link to clipboard"
  >
    <mat-icon svgIcon="link"></mat-icon>
  </button>
</div>
