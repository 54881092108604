<div #ng1Container class="ng1-container"></div>
<ih-admin-base
  editorTitle="Submitted content"
  editorIconPath="M2 17V20H10V18.11H3.9V17C3.9 16.36 7.03 14.9 10 14.9C10.96 14.91 11.91 15.04 12.83 15.28L14.35 13.76C12.95 13.29 11.5 13.03 10 13C7.33 13 2 14.33 2 17M10 4C7.79 4 6 5.79 6 8S7.79 12 10 12 14 10.21 14 8 12.21 4 10 4M10 10C8.9 10 8 9.11 8 8S8.9 6 10 6 12 6.9 12 8 11.11 10 10 10M21.7 13.35L20.7 14.35L18.65 12.35L19.65 11.35C19.86 11.14 20.21 11.14 20.42 11.35L21.7 12.63C21.91 12.84 21.91 13.19 21.7 13.4M12 18.94L18.06 12.88L20.11 14.88L14.11 20.95H12V18.94"
  infiniteScroll
  [infiniteScrollDistance]="5"
  [immediateCheck]="false"
  (scrolled)="loadMore()"
>
  <!-- Hide save button -->
  <div button-controls></div>
  <ih-filter-chips ihAllContentFilterChips (filterChanged)="filterChanged($event)"></ih-filter-chips>
  <div class="submitted-content-list-container">
    <ng-container *ngIf="{ loading: loading$ | async, items: (items$ | async)! } as data">
      <ih-content-list-item
        *ngFor="let item of data.items; let first = first; let last = last; trackBy: trackByFn"
        [item]="item"
        [showContentType]="true"
        (editClicked)="showEditor($event)"
        (publishClicked)="publish($event)"
        (deleteClicked)="deleteItem($event)"
        (cloneClicked)="clone($event)"
        (copyContentLinkClicked)="copyContentLink($event)"
        (showViewersClicked)="showViewers($event)"
      >
      </ih-content-list-item>
      <div class="loading-container" *ngIf="data.loading">
        <mat-spinner></mat-spinner>
      </div>
      <div
        class="not-found fx-flex fx-layout-row fx-layout-align-center-center"
        *ngIf="!data.loading && data.items.length == 0"
      >
        No matching results found
      </div>
    </ng-container>
  </div>
</ih-admin-base>
