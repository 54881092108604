import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SignalRService } from '@ih/services';

@Component({
  selector: 'ih-offline-alert',
  templateUrl: './offline-alert.component.html',
  styleUrls: ['./offline-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatButtonModule]
})
export class OfflineAlertComponent {
  @HostBinding('class.ih-offline-alert') hostClass = true;

  constructor(private signalr: SignalRService) {}

  reconnect(): void {
    this.signalr.connect();
  }
}
