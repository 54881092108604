import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, inject } from '@angular/core';

@Component({
  selector: 'ih-content-card-ghost',
  templateUrl: './content-card-ghost.component.html',
  styleUrls: ['./content-card-ghost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class ContentCardGhostComponent {
  @HostBinding('class.content-card-ghost') hostClass = true;
  public el = inject(ElementRef);
}
