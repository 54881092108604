<div #ng1Container class="ng1-container"></div>
<ih-admin-base
  editorTitle="Events"
  editorIconPath="M3 3V21H21V3H3M18 18H6V17H18V18M18 16H6V15H18V16M18 12H6V6H18V12Z"
  infiniteScroll
  [infiniteScrollDistance]="5"
  [immediateCheck]="false"
  (scrolled)="loadMore()"
>
  <!-- Hide save button -->
  <div button-controls>
    <button mat-flat-button class="events-add-event-button" (click)="showEditor()">Add event</button>
  </div>
  <ih-filter-chips ihEventFilterChips (filterChanged)="filterChanged($event)"></ih-filter-chips>
  <div class="event-list-container">
    <ng-container *ngIf="{ loading: loading$ | async, items: (items$ | async)! } as data">
      <ih-content-list-item
        *ngFor="let item of data.items; let first = first; let last = last; trackBy: trackByFn"
        [item]="item"
        (editClicked)="showEditor($event)"
        (publishClicked)="publish($event)"
        (deleteClicked)="deleteItem($event)"
        (cloneClicked)="clone($event)"
        (copyContentLinkClicked)="copyContentLink($event)"
        (showViewersClicked)="showViewers($event)"
        (showTicketsClicked)="showTickets($event)"
      >
      </ih-content-list-item>
      <div class="loading-container" *ngIf="data.loading">
        <mat-spinner></mat-spinner>
      </div>
      <div
        class="not-found fx-flex fx-layout-row fx-layout-align-center-center"
        *ngIf="!data.loading && data.items.length == 0"
      >
        No matching results found
      </div>
    </ng-container>
  </div>
</ih-admin-base>
