import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  effect,
  inject,
  signal
} from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FroalaComponent } from '@ih/froala';
import { Subject, takeUntil } from 'rxjs';
import { ContentBodyComponent } from '../content-body/content-body.component';
import { EditContentBodyPlaceholderComponent } from '../edit-content-body-placeholder/edit-content-body-placeholder.component';
import { EditPageService } from '../services/edit-page.service';

@Component({
  selector: 'ih-editable-content-body',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,

    FroalaComponent,

    ContentBodyComponent,
    EditContentBodyPlaceholderComponent
  ],
  templateUrl: './editable-content-body.component.html',
  styleUrl: './editable-content-body.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class EditableContentBodyComponent implements OnDestroy {
  @HostBinding('class.show-overlay') get isShowingEditOverlay() {
    return this.showEditOverlay();
  }

  @HostBinding('class.editing') get isEditingClass() {
    return this.isEditing();
  }

  @HostBinding('class.has-content') get hasContent() {
    return this.content();
  }

  @ViewChild('froala', { read: ElementRef }) froala!: ElementRef;

  private el = inject(ElementRef);
  private editPage = inject(EditPageService);
  private ngControl = inject(ControlContainer);

  @Input() controlName!: string;
  @Output() historyClicked = new EventEmitter<void>();

  updatedHtml?: FormControl;

  showEditOverlay = this.editPage.isEditing;

  isEditing = signal<boolean>(false);

  content = signal<string>('');
  @Input() get html(): string {
    return this.content();
  }

  set html(value: string) {
    this.content.set(value);
  }

  private editing$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  constructor() {
    effect(
      () => {
        // we're not using the value, we just need to listen to the signal
        const editing = this.showEditOverlay();
        if (!editing) {
          this.setEditingState(false);
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateContainerHeight(): void {
    // if we're editing we need to set the min-height of the container to the height of the content
    // otherwise we need to set it to 0
    if (this.showEditOverlay()) {
      // get the current element height and set the min-height to that
      // if froala is not available use the native element
      const el = this.froala?.nativeElement ?? (this.el.nativeElement as HTMLElement);
      const height = Math.max(parseInt(el.clientHeight), 170);
      this.el.nativeElement.style.minHeight = `${height}px`;
    } else {
      // remove the min-height
      this.el.nativeElement.style.minHeight = '';
    }
  }

  onHistoryClicked(): void {
    this.historyClicked.emit();
  }

  setEditingState(editing: boolean): void {
    if (editing) {
      this.updatedHtml = (this.ngControl.control as FormGroup).get(this.controlName) as FormControl;
      this.editing$.next();
      // listen to edits so if froala shrinks we can shrink the container
      this.updatedHtml!.valueChanges.pipe(takeUntil(this.editing$), takeUntil(this.destroy$)).subscribe(() => {
        this.updateContainerHeight();
      });
    }
    this.updateContainerHeight();
    this.isEditing.set(editing);
  }
}
