<ng-container *ngIf="calendarOptions$ | async as opts">
  <article id="calendarContainer" class="calendar-container">
    <div class="calendar-shadow-container" [class.expanded]="showCalendar$ | async">
      <header class="calendar-header" matRipple (click)="toggleCalendar()">
        <button mat-icon-button (click)="changeMonth($event, -1)">
          <mat-icon svgIcon="chevron-left"></mat-icon>
        </button>
        <button mat-icon-button (click)="today()">
          <mat-icon svgIcon="calendar-today"></mat-icon>
        </button>
        <button mat-icon-button (click)="changeMonth($event, 1)">
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </button>
        <button
          #monthMenuTrigger="matMenuTrigger"
          mat-button
          class="month-year-button"
          [matMenuTriggerFor]="monthMenu"
          (click)="$event.stopPropagation()"
          (menuOpened)="showMonthMenu()"
        >
          <span class="current-month-name fx-flex">
            <span class="current-month-name-text">{{ opts.currentMonth }}</span>
            <mat-icon class="menu-icon">
              <svg viewBox="0 0 24 24">
                <title>menu-down</title>
                <path d="M7,10L12,15L17,10H7Z" />
              </svg>
            </mat-icon>
          </span>
        </button>
        <mat-menu #monthMenu="matMenu" class="month-menu-dropdown">
          <mat-calendar
            #monthCalendar
            startView="multi-year"
            (monthSelected)="monthSelected($event)"
            (click)="$event.stopPropagation()"
          ></mat-calendar>
        </mat-menu>
        <span class="fx-flex"></span>
        <mat-icon
          class="toggle-calendar-icon"
          [svgIcon]="(showCalendar$ | async) ? 'chevron-up' : 'chevron-down'"
        ></mat-icon>
      </header>
      <div class="day-of-week-header" [class.expanded]="showCalendar$ | async">
        <div class="day-of-week day-of-week-short">S</div>
        <div class="day-of-week day-of-week-short">M</div>
        <div class="day-of-week day-of-week-short">T</div>
        <div class="day-of-week day-of-week-short">W</div>
        <div class="day-of-week day-of-week-short">T</div>
        <div class="day-of-week day-of-week-short">F</div>
        <div class="day-of-week day-of-week-short">S</div>
        <div class="day-of-week day-of-week-long">Sun</div>
        <div class="day-of-week day-of-week-long">Mon</div>
        <div class="day-of-week day-of-week-long">Tue</div>
        <div class="day-of-week day-of-week-long">Wed</div>
        <div class="day-of-week day-of-week-long">Thu</div>
        <div class="day-of-week day-of-week-long">Fri</div>
        <div class="day-of-week day-of-week-long">Sat</div>
      </div>
      <div class="calendar-wrapper" [style.max-height]="(showCalendar$ | async) ? undefined : '0px'">
        <ng-container
          *ngIf="{ selectedDayOfMonth: dayOfMonth$ | async, dayOfMonthHover: dayOfMonthHover$ | async } as dayOpts"
        >
          <div class="week-row" *ngFor="let week of opts.weeks">
            <button
              #dayOfMonth
              class="day-of-month"
              [class.current-month]="dayOfWeek.month === 'current'"
              [class.weekend]="dayOfWeek.isWeekend"
              [class.weekday]="!dayOfWeek.isWeekend"
              [class.day-of-month-hover]="
                dayOpts.dayOfMonthHover?.dayOfMonth === dayOfWeek.dayOfMonth &&
                dayOpts.dayOfMonthHover?.month === dayOfWeek.month
              "
              [attr.aria-label]="dayOfWeek.date | date : 'mediumDate'"
              [attr.data-day-of-month]="dayOfWeek.dayOfMonth"
              [attr.data-month]="dayOfWeek.month"
              *ngFor="let dayOfWeek of week; let i = index; trackBy: trackByDayFn"
              (click)="dateClicked(dayOfWeek.date)"
            >
              <div
                matRipple
                class="day-number fx-layout-row fx-layout-align-center-center"
                [class.is-today]="dayOfWeek.isToday"
                [class.selected-date]="
                  dayOpts.selectedDayOfMonth === dayOfWeek.dayOfMonth && dayOfWeek.month === 'current'
                "
              >
                {{ dayOfWeek.dayOfMonth }}
              </div>

              <div
                class="event-labels-position-container fx-hide-lt-md"
                [style.height]="(dayOfWeek.events.length > 3 ? 3 : dayOfWeek.events.length) * 26 + 'px'"
              >
                <div class="event-labels-container">
                  <button
                    matRipple
                    class="event-container"
                    (click)="eventClicked($event, event, dayOfWeek.dayOfMonth)"
                    [style.width]="(event.width || 1) * 100 + '%'"
                    *ngFor="let event of dayOfWeek.events | slice : 0 : 2; let i = index"
                    (mouseenter)="onHoverEvent(event, true)"
                    (mouseleave)="onHoverEvent(event, false)"
                  >
                    <div
                      class="event-label"
                      [class.event-hover]="event.hovering"
                      [class.starts-on-this-date]="event.startsOnThisDate"
                      [class.event-starts-this-week]="event.startsOnWeek"
                      [class.event-ends-this-week]="event.endsOnWeek"
                      [ngClass]="'event-color-' + event.colorIndex"
                      [attr.title]="event.title"
                    >
                      <span *ngIf="event.startsOnThisDate">
                        {{ event.title }}
                        <span *ngIf="event.hasRSVP">
                          <span class="event-rsvp-separator">|</span>
                          <span class="event-rsvp">Registered</span>
                        </span>
                      </span>
                    </div>
                  </button>
                  <button
                    mat-button
                    class="show-more-btn"
                    *ngIf="dayOfWeek.events.length > 3"
                    [matMenuTriggerFor]="menu"
                    [matMenuTriggerData]="{ dayOfWeek: dayOfWeek }"
                    (click)="$event.stopPropagation()"
                  >
                    <span>{{ dayOfWeek.events.length - 2 }} more</span>
                  </button>
                  <mat-menu class="more-events-menu" #menu="matMenu">
                    <ng-template matMenuContent let-dayOfWeek="dayOfWeek">
                      <button
                        mat-menu-item
                        (click)="eventClicked(null, event, dayOfWeek.dayOfMonth)"
                        *ngFor="let event of dayOfWeek.events; trackBy: trackByEventFn"
                      >
                        <div class="event-color-marker" [ngClass]="'event-color-' + event.colorIndex"></div>
                        <div class="event-title">
                          {{ event.title }}
                        </div>
                      </button>
                    </ng-template>
                  </mat-menu>
                </div>
              </div>
              <div class="has-event" *ngIf="dayOfWeek.events.length > 0"></div>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </article>
  <div class="no-events-container" *ngIf="(noEvents$ | async) && !(busy$ | async)">
    <div class="no-events-shadow mat-elevation-z1">
      <mat-icon class="no-events-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>calendar-remove</title>
          <path
            d="M19,19H5V8H19M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M9.31,17L11.75,14.56L14.19,17L15.25,15.94L12.81,13.5L15.25,11.06L14.19,10L11.75,12.44L9.31,10L8.25,11.06L10.69,13.5L8.25,15.94L9.31,17Z"
          />
        </svg>
      </mat-icon>
      <span class="no-events-label">No events for this month</span>
    </div>
  </div>
  <div class="event-list">
    <div
      #dayContainer
      class="day-container"
      [attr.data-day]="day.dayOfMonth"
      *ngFor="let day of opts.days; trackBy: trackByDayFn"
    >
      <ng-container *ngIf="day.events.length > 0">
        <header class="date-header fx-layout-row fx-layout-align-start-center">
          <span class="date-header-text fx-flex">{{ day.date | date : 'fullDate' }} </span>
          <button class="copy-link-button" mat-icon-button (click)="copyLink(day.date)">
            <mat-icon svgIcon="link"></mat-icon>
          </button>
        </header>
        <ih-virtual-masonry>
          <ih-item-wrapper
            #cardContainer
            class="card-container"
            [item]="event"
            *ngFor="let event of day.events; trackBy: trackByEventFn"
          >
            <ng-template let-event>
              <ih-content-card
                [ngClass]="'events-' + event.contentId + '-' + day.dayOfMonth"
                class="content-card"
                #cardList
                alphaMinHeight="100px"
                [item]="event"
              >
              </ih-content-card>
            </ng-template>
          </ih-item-wrapper>
        </ih-virtual-masonry>
      </ng-container>
    </div>
  </div>
  <ih-content-card-ghost class="content-card" *ngIf="busy$ | async"></ih-content-card-ghost>
  <ih-content-card-ghost class="content-card" *ngIf="busy$ | async"></ih-content-card-ghost>
  <ih-content-card-ghost class="content-card" *ngIf="busy$ | async"></ih-content-card-ghost>
  <ih-content-card-ghost class="content-card" *ngIf="busy$ | async"></ih-content-card-ghost>
</ng-container>
