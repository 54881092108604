import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AdminBaseComponent } from '@ih/admin-base';
import { ContentListItemComponent } from '@ih/content-list-item';
import { SaveChangesDialogService } from '@ih/dialogs';
import { ContentOption, ContentState, ContentStatus, ContentTypes } from '@ih/enums';
import { EventFilterChipsDirective, FilterChipsComponent } from '@ih/filter-chips';
import { ContentListItem, ListFilterItem, ListFilterItemValue } from '@ih/interfaces';
import { LazyDialogService } from '@ih/lazy-dialog';
import { AuthService, ClipboardService, ContentService, LazySnackBarService, SecurityService } from '@ih/services';
import { copyTextWithMessage, getChannelMdiIconSvg } from '@ih/utilities';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { AttendeesDialogComponent } from '../attendees-dialog/attendees-dialog.component';
import { ContentViewersDialogComponent } from '../content-viewers-dialog/content-viewers-dialog.component';

@Component({
  selector: 'ih-event-list',
  standalone: true,
  imports: [
    AsyncPipe,
    NgForOf,
    NgIf,
    RouterModule,

    MatButtonModule,
    MatProgressSpinnerModule,
    ScrollingModule,

    AdminBaseComponent,
    ContentListItemComponent,
    EventFilterChipsDirective,
    FilterChipsComponent,

    ContentViewersDialogComponent,
    InfiniteScrollModule
  ],
  templateUrl: './event-list.component.html',
  styleUrl: './event-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventListComponent implements OnInit, OnDestroy {
  @HostListener('class.ih-post-list') hostClass = true;

  @ViewChild(ContentListItemComponent, { read: ElementRef }) ihPostListItem!: ElementRef;

  private http = inject(HttpClient);
  private snackbar = inject(LazySnackBarService);
  private saveChangesDialog = inject(SaveChangesDialogService);
  private auth = inject(AuthService);
  private security = inject(SecurityService);
  private route = inject(ActivatedRoute);
  private content = inject(ContentService);
  private lazyDialog = inject(LazyDialogService);
  private clipboard = inject(ClipboardService);
  private router = inject(Router);

  private skip$ = new BehaviorSubject<number>(0);
  private filters$ = new BehaviorSubject<ListFilterItem[]>([]);

  private destroy$ = new Subject<void>();

  items$ = new BehaviorSubject<ContentListItem[]>([]);

  editingOrder$ = new BehaviorSubject<boolean>(false);

  loading$ = new BehaviorSubject<boolean>(true);

  ngOnInit(): void {
    this.saveChangesDialog.pageTitle = 'event list';

    this.route.data.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.skip$.next(0);
      this.filters$.next([]);
    });

    combineLatest([this.skip$, this.filters$])
      .pipe(
        debounceTime(100),
        switchMap(([skip, filters]) => {
          this.loading$.next(true);

          const params: { [key: string]: string } = {
            contentType: ContentTypes.Event,
            skip: skip.toString(),
            take: '40'
          };
          filters.forEach((filter) => {
            params[filter.queryParam] = filter.multiple
              ? JSON.stringify((filter.query as ListFilterItemValue[]).map((v) => v.value))
              : ((filter.query as ListFilterItemValue).value as string);
          });

          return this.http.get<ContentListItem[]>('/api/content', {
            params
          });
        }),
        catchError((err, caught) => {
          this.loading$.next(false);

          this.snackbar.open('Error loading posts', 'TRY AGAIN').then((ref) => ref.onAction().subscribe(() => caught));

          throw err;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((items) => {
        this.loading$.next(false);

        // set status on each item based on properties of the item
        // also set channel svgs
        items.forEach((item) => {
          item.channels.forEach((channel) => {
            channel.svg = getChannelMdiIconSvg(channel.joinType);
          });

          item.canEdit = this.security.canItem(
            ContentOption.Edit,
            item.channels.map((c) => c.channelId),
            this.auth.currentUser$.value!.campaign_user_id === item.createdBy?.authorId
          );

          item.status = this.content.getContentStatus(item.active, item.archived, item.startDate!, item.endDate!);
        });
        this.items$.next([...this.items$.value, ...items]);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  loadMore(): void {
    if (this.loading$.value === true) {
      return;
    }

    this.skip$.next(this.skip$.value + 40);
  }

  contentItemHash(index: number, item: ContentListItem): string {
    return 'event' + item.contentId.toString() + item.updatedAt?.toString();
  }

  filterChanged(filters: ListFilterItem[]): void {
    this.filters$.next(filters);
    this.skip$.next(0);
    this.items$.next([]);
  }

  publish(item: ContentListItem) {
    const imperativeTense = item.active ? 'Unpublish' : 'Publish';
    const presentTense = item.active ? 'Unpublishing' : 'Publishing';
    const pastTense = item.active ? 'Unpublished' : 'Published';
    const status = item.active ? ContentStatus.Draft : ContentStatus.Published;

    this.updateState(item, status, imperativeTense, presentTense, pastTense);
  }

  deleteItem(item: ContentListItem) {
    const imperativeTense = item.archived ? 'Restore' : 'Delete';
    const presentTense = item.archived ? 'Restoring' : 'Deleting';
    const pastTense = item.archived ? 'Restored' : 'Deleted';
    const status = item.archived ? ContentStatus.Draft : ContentStatus.Deleted;

    this.updateState(item, status, imperativeTense, presentTense, pastTense);
  }

  private updateState(
    item: ContentListItem,
    status: ContentStatus,
    imperativeTense: string,
    presentTense: string,
    pastTense: string
  ) {
    this.snackbar.open(`${presentTense} please wait...`, undefined, {
      duration: 60000
    });

    let state: ContentState;
    let active = false;
    let archived = false;
    switch (status) {
      case ContentStatus.Draft:
        state = ContentState.Draft;
        break;
      case ContentStatus.Published:
        state = ContentState.Published;
        active = true;
        break;
      case ContentStatus.Deleted:
        state = ContentState.Deleted;
        archived = true;
        break;
      default:
        throw new Error('Invalid state');
    }
    this.http
      .put(`/api/${item.contentType}/${item.contentId}/state`, state)
      .pipe(
        catchError((err, caught) => {
          this.snackbar.dismiss();
          this.snackbar
            .open(
              `Something went wrong while we tried to ${imperativeTense.toLocaleLowerCase()} that. Please contact us`,
              'TRY AGAIN'
            )
            .then((ref) => ref.onAction().subscribe(() => setTimeout(() => caught)));

          throw err;
        })
      )
      .subscribe(() => {
        this.snackbar.open(pastTense);
        // update item in list immutably
        const items = this.items$.value;
        const index = items.findIndex((i) => i.contentId === item.contentId);
        const publishedAt = status === ContentStatus.Published ? new Date() : items[index].publishedAt;
        items[index] = { ...items[index], status, active, archived, publishedAt };
        this.items$.next([...items]);
      });
  }

  clone(item: ContentListItem) {
    this.snackbar.open('Cloning please wait...', undefined, {
      duration: 60000
    });
    this.http.post(`/api/${item.contentType}/${item.contentId}/clone`, {}).subscribe({
      next: () => {
        this.snackbar.open('Cloning process complete');
        this.skip$.next(0);
        this.items$.next([]);
      },
      error: (resp) => {
        this.snackbar.dismiss();
        console.error('Unable to clone', resp);
        this.snackbar
          .open('Something went wrong while we tried to clone that. Please contact us', 'TRY AGAIN')
          .then((ref) => ref.onAction().subscribe(() => setTimeout(() => this.clone(item))));
      }
    });
  }

  copyContentLink(item: ContentListItem): void {
    const message = copyTextWithMessage(
      {
        path: 'events/',
        id: `${item.contentId}/`,
        slug: `${item.slug ? item.slug : ''}`
      },
      'url',
      false
    );
    this.clipboard.copyTextToClipboard(message.contentToCopy).then(
      () => {
        this.snackbar.open(message.successMessage);
      },
      () => {
        this.snackbar.open(message.failMessage);
      }
    );
  }

  async showEditor(event?: ContentListItem): Promise<void> {
    this.router.navigate(['/communicate', 'events', event?.contentId ?? 'new']);
  }

  async showViewers(item: ContentListItem) {
    const dialog = await this.lazyDialog.getDialogService();

    dialog.open(ContentViewersDialogComponent, {
      data: { contentType: item.contentType, contentId: item.contentId },
      closeOnNavigation: true,
      panelClass: ['content-viewers-dialog', 'basic-dialog'],
      maxWidth: undefined,
      width: undefined,
      minHeight: undefined
    });
  }

  async showTickets(item: ContentListItem): Promise<void> {
    const dialog = await this.lazyDialog.getDialogService();

    dialog.open(AttendeesDialogComponent, {
      data: item.contentId,
      closeOnNavigation: true,
      panelClass: ['attendees-dialog', 'basic-dialog'],
      maxWidth: undefined,
      width: undefined,
      minHeight: undefined
    });
  }

  trackByFn(index: number, item: ContentListItem): string {
    return item.contentId.toString();
  }
}
