import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  inject
} from '@angular/core';
import { FroalaComponent } from '@ih/froala';
import { LazyComponentService, ScriptService } from '@ih/services';
import { getTextFromHTML, hasRTLChars, hashString, isRTL } from '@ih/utilities';
import { Observable, Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { EditContentBodyPlaceholderComponent } from '../edit-content-body-placeholder/edit-content-body-placeholder.component';

@Component({
  standalone: true,
  selector: 'ih-content-body',
  templateUrl: './content-body.component.html',
  styleUrls: ['./content-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FroalaComponent, EditContentBodyPlaceholderComponent]
})
export class ContentBodyComponent implements AfterViewInit, OnChanges, OnDestroy {
  @HostBinding('class.ih-content-body') ihContentBody = true;
  @HostBinding('class.fr-view') frView = true;
  @HostBinding('dir') dir = 'auto';

  @Input() html!: string;

  scriptQueue$ = new Subject<HTMLScriptElement>();

  private destroy$ = new Subject<void>();

  private el = inject(ElementRef);
  private renderer = inject(Renderer2);
  private script = inject(ScriptService);
  private lazyComponent = inject(LazyComponentService);

  constructor() {
    this.scriptQueue$
      .pipe(
        mergeMap(
          (scriptToInject) =>
            new Observable((subscriber) => {
              // create a new script tag and copy the script over
              const script: HTMLScriptElement = document.createElement('script');
              // copy the script content over, if any
              script.innerHTML = scriptToInject.innerHTML;
              if (script.innerHTML) {
                // hash the script and add a sourcemap comment so it will show in dev tools
                script.innerHTML += `\n//# sourceURL=${hashString(script.innerHTML)}`;
              }

              // copy the attributes over, if any
              for (let a = 0; a < scriptToInject.attributes.length; a++) {
                const attr: Attr = scriptToInject.attributes.item(a)!.cloneNode(true) as Attr;
                script.attributes.setNamedItem(attr);
              }

              // if the script is not external then we don't need to wait for it to load
              if (!script.src) {
                console.debug('script loaded', script);
                scriptToInject.replaceWith(script);
                subscriber.next(script);
                subscriber.complete();
                return;
              }

              script.onload = () => {
                console.debug('script loaded', script);
                subscriber.next(script);
                subscriber.complete();

                // if the script is for instagram embeds, we need to call the process embeds function
                // if instagram has already been loaded process embeds
                // insta embeds only process on script load for some raison
                if (window.instgrm && window.instgrm.Embeds) {
                  window.instgrm.Embeds.process();
                }
              };
              script.onerror = (error) => {
                // swallow exception so other scripts will load
                console.warn('script injection failure', error, script);
                subscriber.next(script);
                subscriber.complete();
              };

              script.src = scriptToInject.src;

              // mark script as async false so it will load and execute immediately
              script.async = false;

              // replace the existing script in context so it will execute as expected
              scriptToInject.replaceWith(script);
            }),
          1
        )
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  ngAfterViewInit() {
    if (hasRTLChars(this.html)) {
      if (isRTL(this.html)) {
        this.renderer.addClass(this.el.nativeElement, 'rtl');
      } else {
        this.renderer.addClass(this.el.nativeElement, 'ltr');
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const el: HTMLElement = this.el.nativeElement;
    // reset element
    el.innerText = '';

    if (changes['html'] && this.html) {
      // check if there are rtl chars in the html
      if (hasRTLChars(this.html)) {
        // get text from html
        const text = getTextFromHTML(this.html);
        if (isRTL(text)) {
          this.dir = 'rtl';
        } else {
          this.dir = 'auto';
        }
      }

      // lazy load the ih-purchase-button component if it exists in the html
      if (this.html.indexOf('ih-purchase-button') !== -1) {
        this.lazyComponent.loadComponent('ih-purchase-button');
      }

      // check if html has fr-embedly
      if (this.html.indexOf('fr-embedly') !== -1) {
        this.script.loadEmbedly().subscribe();
      }
      el.innerHTML = this.html;
      const elements = el.querySelectorAll('script');

      elements.forEach((element) => {
        this.scriptQueue$.next(element);
      });

      // add clearfix element
      const clearfix = document.createElement('div');
      clearfix.classList.add('clearfix');
      el.append(clearfix);
    }
  }
}
