<!--Survey content-->

<div
  class="survey-content-container mat-elevation-z1"
  [class.must-login]="!survey.allowUnregistered && !(emailVerified$ | async)"
  *ngIf="survey$ | async as survey"
>
  <!--Log in warning-->
  <div class="survey-login-overlay" *ngIf="!survey.allowUnregistered && !(emailVerified$ | async)">
    <ih-must-login>
      <div class="survey-login-overlay-text">You must be logged in and have a verified account to take this poll</div>
    </ih-must-login>
  </div>

  <mat-stepper
    #stepper
    orientation="vertical"
    [linear]="true"
    (selectionChange)="stepSelected($event)"
    *ngIf="!survey.completed"
  >
    <mat-step
      *ngFor="let question of survey.questions; let i = index"
      [stepControl]="answers.controls[i]"
      [completed]="stepper.selectedIndex > i"
      [formGroup]="$any(answers.controls[i])"
    >
      <ng-template matStepLabel>
        <div class="question-header">
          <div class="question-header-text">{{ question.text }}</div>
        </div>
      </ng-template>

      <div class="question-content">
        <!--Multiple choice-->
        <div
          class="question-content-radio-container"
          [ngClass]="{ 'has-error': answers.get(i + '.optionId')!.invalid && answers.get(i + '.optionId')!.touched }"
          *ngIf="question.questionTypeId == 1"
        >
          <mat-radio-group formControlName="optionId">
            <mat-radio-button *ngFor="let option of question.options" [value]="option.id">
              {{ option.text }}
            </mat-radio-button>
          </mat-radio-group>
          <mat-error
            *ngIf="answers.get(i + '.optionId')!.getError('required') && answers.get(i + '.optionId')!.touched"
          >
            Please select an option
          </mat-error>
        </div>

        <!--Multiple Select-->
        <div formGroupName="optionId" class="question-content-checkbox-container" *ngIf="question.questionTypeId == 2">
          <mat-checkbox
            *ngFor="let option of question.options"
            [formControlName]="option.id!"
            [value]="option.id!.toString()"
          >
            {{ option.text }}
          </mat-checkbox>
          <mat-error
            *ngIf="answers.get(i + '.optionId')!.getError('required') && answers.get(i + '.optionId')!.touched"
          >
            Please select an option
          </mat-error>
        </div>

        <!--Short answer-->
        <div class="question-content-text" *ngIf="question.questionTypeId == 3">
          <mat-form-field hideRequiredMarker>
            <textarea
              dir="auto"
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              maxlength="1001"
              placeholder="Type your answer here"
              formControlName="optionId"
              (keydown)="answers.get(i + '.optionId')!.markAsTouched()"
            ></textarea>
            <mat-error *ngIf="answers.get(i + '.optionId')!.hasError('required')"> Please enter a response </mat-error>
            <mat-error *ngIf="answers.get(i + '.optionId')!.hasError('maxlength')">
              Please keep your response shorter than 1000 characters
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!--Previous and Next buttons-->
      <div class="survey-btn-container" *ngIf="question.questionTypeId !== 1 || i === survey.questions.length - 1">
        <div
          class="survey-previous-next-btn-container"
          *ngIf="question.questionTypeId !== 1 && i < survey.questions.length - 1"
        >
          <!--Next-->
          <button
            class="survey-next-btn"
            mat-flat-button
            color="accent"
            matStepperNext
            [disabled]="answers.controls[i].invalid"
            trackClick="Survey Detail Next Finish button clicked"
          >
            Next
          </button>
        </div>
        <div class="submit-btn-container" *ngIf="i === survey.questions.length - 1">
          <!--Next-->
          <button
            class="survey-next-btn"
            mat-flat-button
            color="accent"
            [disabled]="answers.controls[i].invalid"
            (click)="submit()"
            trackClick="Survey Detail Next Finish button clicked"
          >
            Submit
          </button>
        </div>
      </div>
    </mat-step>

    <ng-template matStepperIcon="edit">
      <mat-icon svgIcon="check"></mat-icon>
    </ng-template>
  </mat-stepper>

  <div class="survey-submitting-container" *ngIf="submitting$ | async">
    <div class="fx-layout-column fx-layout-align-center-center">
      <h4>Submitting your answers...</h4>
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <!--Thank you-->
  <div class="survey-thank-you-container fx-layout-row fx-layout-align-center" *ngIf="survey.completed">
    <div class="survey-thank-you-registered" *ngIf="isAuthenticated$ | async">Thank you for participating</div>

    <div class="survey-thank-you-unregistered" *ngIf="survey.allowUnregistered && !(isAuthenticated$ | async)">
      <div class="text-center">Thank you for participating</div>
      <div class="text-center">Why not register while you're here?</div>
      <div class="text-center">
        <button mat-raised-button routerLink="/account/register">REGISTER</button>
      </div>
    </div>
  </div>

  <!--Survey summary-->
  <div class="survey-summary" *ngIf="survey.completed">
    <!--Survey results-->
    <ng-container *ngFor="let question of survey.questions">
      <ng-container *ngIf="!survey.hideResults">
        <div class="survey-summary-container">
          <div class="survey-question primary-background-color primary-font-color">{{ question.text | emoji }}</div>
          <div class="survey-answers">
            <ng-container *ngIf="question.questionTypeId != 3">
              <div class="option fx-layout-column" *ngFor="let option of question.options; let last = last">
                <div class="survey-option-row fx-layout-row" [ngClass]="{ 'survey-option-mine': option.myPick }">
                  <div class="survey-option-text fx-flex">{{ option.text }}</div>
                  <div class="survey-percentage-container fx-layout-align-end-center">
                    <mat-icon svgIcon="check" *ngIf="option.myPick"></mat-icon>
                    <div class="survey-percentage">
                      {{ (option.total! / question.total! || 0) * 100 | number: '1.0-1' }}%
                    </div>
                  </div>
                </div>

                <div class="survey-divider-container fx-layout-row fx-layout-align-center-center" *ngIf="!last">
                  <mat-divider class="survey-divider fx-layout-row fx-layout-align-center-center fx-flex"></mat-divider>
                </div>
              </div>
            </ng-container>
            <div
              class="survey-option-row fx-layout-row"
              [ngClass]="{ 'survey-option-mine': question.myAnswerText != null }"
              *ngIf="question.questionTypeId == 3"
            >
              <div class="survey-option-text fx-flex fx-layout-align-start-start">
                YOUR ANSWER: {{ question.myAnswerText }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="survey-admin-buttons" *ngIf="canExport$ | async">
    <a
      mat-flat-button
      [href]="exportUrl$ | async"
      target="_blank"
      trackClick="Post survey detail export button clicked"
    >
      EXPORT RESULTS
    </a>
  </div>
</div>
