import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  inject,
  signal
} from '@angular/core';
import { CompactNumberPipe } from '@ih/pipes';
import { AuthService } from '@ih/services';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ih-heart',
  standalone: true,
  imports: [CommonModule, CompactNumberPipe],
  templateUrl: './heart.component.html',
  styleUrls: ['./heart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeartComponent {
  private auth = inject(AuthService);

  @HostBinding('class.ih-heart') hostClass = true;
  @HostBinding('class.ih-heart--upvoted') get hostClassUpvoted() {
    return this.upvotes > 0;
  }

  @ViewChild('heartInput') heartInputRef!: ElementRef<HTMLInputElement>;

  hearted = signal<boolean>(false);
  unhearted = signal<boolean>(false);

  upvotes$ = new BehaviorSubject<number>(0);
  @Input()
  get upvotes(): number {
    return this.upvotes$.value;
  }

  set upvotes(v: number) {
    this.upvotes$.next(v);
  }

  isUpvoted$ = new BehaviorSubject<boolean>(false);
  @Input()
  get isUpvoted(): boolean {
    return this.isUpvoted$.value;
  }

  set isUpvoted(v: boolean) {
    this.isUpvoted$.next(v);
  }

  static idCounter = 0;

  checkboxId: string = `heart-${HeartComponent.idCounter++}`;

  changed(v: boolean) {
    if (window.isTemplate) {
      return;
    }

    if (!this.auth.isAuthenticated()) {
      this.heartInputRef.nativeElement.checked = false;
      this.voted.emit(false);
      return;
    }

    this.hearted.set(v);
    this.unhearted.set(!v);

    this.voted.emit(v);
  }

  @Output()
  voted = new EventEmitter<boolean>();
}
