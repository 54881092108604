<input
  #heartInput
  class="heart-checkbox"
  [class.hearted]="hearted()"
  [class.unhearted]="unhearted()"
  [attr.id]="checkboxId"
  type="checkbox"
  [checked]="isUpvoted$ | async"
  (change)="changed($any($event.target).checked)"
/>
<label [attr.for]="checkboxId" class="no-select">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.2 87.507">
    <g id="ih-hearts" transform="translate(-787.902 -454.998)">
      <g class="ih-right-hearts">
        <g opacity="0.484">
          <path
            d="M36.508,16.436c-3.141,6.041-11.545,14.257-16.3,18.634a1.342,1.342,0,0,1-1.8,0C13.633,30.693,5.229,22.477,2.087,16.436c-6.9-13.29,10.5-22.151,17.21-8.86C26.01-5.715,43.409,3.146,36.508,16.436Z"
            transform="translate(936.502 486.145)"
            fill="#faa298"
          />
          <path
            d="M19.311,37.916a3.836,3.836,0,0,1-2.575-.99l-.013-.012C11.871,32.47,3.229,24.051-.131,17.589A15.428,15.428,0,0,1-2,10.374,12.021,12.021,0,0,1-.282,4.2,11.848,11.848,0,0,1,16.364.456a13.647,13.647,0,0,1,2.934,2.6,13.649,13.649,0,0,1,2.934-2.6A11.848,11.848,0,0,1,38.879,4.2,12.02,12.02,0,0,1,40.6,10.374a15.428,15.428,0,0,1-1.87,7.214A52.812,52.812,0,0,1,30.8,28.07c-3.2,3.482-6.607,6.728-8.9,8.839l-.018.017a3.836,3.836,0,0,1-2.571.99ZM9.864,3.5A6.907,6.907,0,0,0,3.991,6.8c-1.423,2.342-1.311,5.357.315,8.489,1.013,1.948,4.482,7.467,15,17.213,2.172-2.025,5.076-4.836,7.815-7.813a48.2,48.2,0,0,0,7.166-9.4c1.626-3.131,1.738-6.146.315-8.488a6.848,6.848,0,0,0-9.644-2.149A10.185,10.185,0,0,0,21.529,8.7L19.3,13.121,17.066,8.7a10.185,10.185,0,0,0-3.432-4.057A6.906,6.906,0,0,0,9.864,3.5Z"
            transform="translate(936.502 486.145)"
            fill="#faa298"
          />
        </g>
        <g opacity="0.484">
          <path
            d="M36.508,16.436c-3.141,6.041-11.545,14.257-16.3,18.634a1.342,1.342,0,0,1-1.8,0C13.633,30.693,5.229,22.477,2.087,16.436c-6.9-13.29,10.5-22.151,17.21-8.86C26.01-5.715,43.409,3.146,36.508,16.436Z"
            transform="translate(906.04 497.584)"
            fill="#e74c3c"
          />
          <path
            d="M19.311,37.916a3.836,3.836,0,0,1-2.575-.99l-.013-.012C11.871,32.47,3.229,24.051-.131,17.589A15.428,15.428,0,0,1-2,10.374,12.021,12.021,0,0,1-.282,4.2,11.848,11.848,0,0,1,16.364.456a13.647,13.647,0,0,1,2.934,2.6,13.649,13.649,0,0,1,2.934-2.6A11.848,11.848,0,0,1,38.879,4.2,12.02,12.02,0,0,1,40.6,10.374a15.428,15.428,0,0,1-1.87,7.214A52.812,52.812,0,0,1,30.8,28.07c-3.2,3.482-6.607,6.728-8.9,8.839l-.018.017a3.836,3.836,0,0,1-2.571.99ZM9.864,3.5A6.907,6.907,0,0,0,3.991,6.8c-1.423,2.342-1.311,5.357.315,8.489,1.013,1.948,4.482,7.467,15,17.213,2.172-2.025,5.076-4.836,7.815-7.813a48.2,48.2,0,0,0,7.166-9.4c1.626-3.131,1.738-6.146.315-8.488a6.848,6.848,0,0,0-9.644-2.149A10.185,10.185,0,0,0,21.529,8.7L19.3,13.121,17.066,8.7a10.185,10.185,0,0,0-3.432-4.057A6.906,6.906,0,0,0,9.864,3.5Z"
            transform="translate(906.04 497.584)"
            fill="#e74c3c"
          />
        </g>
      </g>
      <g class="ih-left-hearts">
        <g opacity="0.484">
          <path
            d="M36.508,16.436c-3.141,6.041-11.545,14.257-16.3,18.634a1.342,1.342,0,0,1-1.8,0C13.633,30.693,5.229,22.477,2.087,16.436c-6.9-13.29,10.5-22.151,17.21-8.86C26.01-5.715,43.409,3.146,36.508,16.436Z"
            transform="translate(827.502 483.705)"
            fill="#faa298"
          />
          <path
            d="M19.311,37.916a3.836,3.836,0,0,1-2.575-.99l-.013-.012C11.871,32.47,3.229,24.051-.131,17.589A15.428,15.428,0,0,1-2,10.374,12.021,12.021,0,0,1-.282,4.2,11.848,11.848,0,0,1,16.364.456a13.647,13.647,0,0,1,2.934,2.6,13.649,13.649,0,0,1,2.934-2.6A11.848,11.848,0,0,1,38.879,4.2,12.02,12.02,0,0,1,40.6,10.374a15.428,15.428,0,0,1-1.87,7.214A52.812,52.812,0,0,1,30.8,28.07c-3.2,3.482-6.607,6.728-8.9,8.839l-.018.017a3.836,3.836,0,0,1-2.571.99ZM9.864,3.5A6.907,6.907,0,0,0,3.991,6.8c-1.423,2.342-1.311,5.357.315,8.489,1.013,1.948,4.482,7.467,15,17.213,2.172-2.025,5.076-4.836,7.815-7.813a48.2,48.2,0,0,0,7.166-9.4c1.626-3.131,1.738-6.146.315-8.488a6.848,6.848,0,0,0-9.644-2.149A10.185,10.185,0,0,0,21.529,8.7L19.3,13.121,17.066,8.7a10.185,10.185,0,0,0-3.432-4.057A6.906,6.906,0,0,0,9.864,3.5Z"
            transform="translate(827.502 483.705)"
            fill="#faa298"
          />
        </g>
        <g opacity="0.484">
          <path
            d="M36.508,16.436c-3.141,6.041-11.545,14.257-16.3,18.634a1.342,1.342,0,0,1-1.8,0C13.633,30.693,5.229,22.477,2.087,16.436c-6.9-13.29,10.5-22.151,17.21-8.86C26.01-5.715,43.409,3.146,36.508,16.436Z"
            transform="translate(789.902 456.497)"
            fill="#e74c3c"
          />
          <path
            d="M19.311,37.916a3.836,3.836,0,0,1-2.575-.99l-.013-.012C11.871,32.47,3.229,24.051-.131,17.589A15.428,15.428,0,0,1-2,10.374,12.021,12.021,0,0,1-.282,4.2,11.848,11.848,0,0,1,16.364.456a13.647,13.647,0,0,1,2.934,2.6,13.649,13.649,0,0,1,2.934-2.6A11.848,11.848,0,0,1,38.879,4.2,12.02,12.02,0,0,1,40.6,10.374a15.428,15.428,0,0,1-1.87,7.214A52.812,52.812,0,0,1,30.8,28.07c-3.2,3.482-6.607,6.728-8.9,8.839l-.018.017a3.836,3.836,0,0,1-2.571.99ZM9.864,3.5A6.907,6.907,0,0,0,3.991,6.8c-1.423,2.342-1.311,5.357.315,8.489,1.013,1.948,4.482,7.467,15,17.213,2.172-2.025,5.076-4.836,7.815-7.813a48.2,48.2,0,0,0,7.166-9.4c1.626-3.131,1.738-6.146.315-8.488a6.848,6.848,0,0,0-9.644-2.149A10.185,10.185,0,0,0,21.529,8.7L19.3,13.121,17.066,8.7a10.185,10.185,0,0,0-3.432-4.057A6.906,6.906,0,0,0,9.864,3.5Z"
            transform="translate(789.902 456.497)"
            fill="#e74c3c"
          />
        </g>
      </g>
      <g class="ih-center-heart">
        <path
          class="ih-inner"
          d="M68.82,30.286C62.86,41.748,46.92,57.336,37.9,65.639a2.547,2.547,0,0,1-3.413,0c-9.068-8.3-25.012-23.892-30.972-35.353C-9.578,5.07,23.432-11.741,36.167,13.475,48.9-11.741,81.912,5.07,68.82,30.286Z"
          transform="translate(853.502 473.705)"
          fill="#e74c3c"
        />
        <path
          class="ih-outer"
          d="M36.192,68.8a5.038,5.038,0,0,1-3.382-1.3l-.013-.012C28.5,63.55,22.1,57.47,16,50.84,8.968,43.21,4.022,36.682,1.3,31.439A27.058,27.058,0,0,1-2,18.8,20.564,20.564,0,0,1,.934,8.233,20.236,20.236,0,0,1,29.375,1.847a24.62,24.62,0,0,1,6.792,6.728,24.623,24.623,0,0,1,6.791-6.728A20.236,20.236,0,0,1,71.4,8.233,20.562,20.562,0,0,1,74.336,18.8a27.059,27.059,0,0,1-3.3,12.641c-2.723,5.236-7.666,11.763-14.693,19.4C50.32,57.389,43.909,63.5,39.592,67.478l-.018.017A5.038,5.038,0,0,1,36.192,68.8Zm-.029-5.01a.047.047,0,0,0,.057,0c4.247-3.912,10.543-9.916,16.446-16.332C59.4,40.14,64.084,33.976,66.6,29.132a22.135,22.135,0,0,0,2.734-10.306A15.233,15.233,0,0,0,45.688,6.037,21.52,21.52,0,0,0,38.4,14.6l-2.232,4.418L33.935,14.6a21.521,21.521,0,0,0-7.289-8.566A15.231,15.231,0,0,0,3,18.827,22.133,22.133,0,0,0,5.732,29.134c2.522,4.85,7.213,11.014,13.941,18.319,5.982,6.495,12.268,12.465,16.491,16.333Z"
          transform="translate(853.502 473.705)"
          fill="#e74c3c"
        />
      </g>
    </g>
  </svg>
</label>
<ng-container *ngIf="upvotes$ | async as upvotes">
  <span class="upvote-count">{{ upvotes > 0 ? (upvotes | compactNumber) : '' }}</span>
</ng-container>
