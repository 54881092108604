@defer (when showEditOverlay() || isEditing()) {
  @if (showEditOverlay() && !isEditing()) {
    <ih-edit-content-body-placeholder (click)="setEditingState(true)">
      <div class="fx-layout-row fx-layout-align-center">
        @if (content()) {
          <mat-icon class="edit-content-body-placeholder-icon" svgIcon="pencil"></mat-icon>
          <span class="edit-content-body-placeholder-text">Edit customization</span>
        } @else {
          <mat-icon class="edit-content-body-placeholder-icon" svgIcon="plus-circle-outline"></mat-icon>
          <span class="edit-content-body-placeholder-text">Add customization</span>
        }
      </div>
    </ih-edit-content-body-placeholder>
  }
  @if (isEditing() && updatedHtml) {
    <div class="editing-container">
      <ih-froala
        #froala
        class="froala"
        cdkFocusInitial
        contentType="channels"
        placeholder="Type something..."
        name="body"
        id="html"
        [showHistoryButton]="true"
        (historyClicked)="onHistoryClicked()"
        [toolbarStickyOffset]="112"
        [allowFileUpload]="true"
        [disableSanitizer]="true"
        [allowEmbedly]="false"
        [formControl]="updatedHtml"
      >
      </ih-froala>
    </div>
  }
}
@if (!isEditing()) {
  <ih-content-body ihInterceptHref [html]="content()"> </ih-content-body>
}
