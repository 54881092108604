import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { RouterLink } from '@angular/router';
import { MustLoginComponent } from '@ih/authentication';
import { EmojiPipe } from '@ih/emoji';
import { ContentTypes, SurveyQuestionType } from '@ih/enums';
import { PostSurvey } from '@ih/interfaces';
import { AuthService, ContentService, LazySnackBarService, TimeService } from '@ih/services';
import { atLeastOneCheckboxCheckedValidator } from '@ih/validators';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ih-post-survey-detail',
  templateUrl: './post-survey-detail.component.html',
  styleUrls: ['./post-survey-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    DecimalPipe,
    NgClass,
    NgFor,
    NgIf,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatStepperModule,
    ReactiveFormsModule,
    TextFieldModule,
    EmojiPipe,
    MustLoginComponent,
    RouterLink
  ]
})
export class PostSurveyDetailComponent implements OnInit {
  @HostBinding('class.ih-post-survey-detail') hostClass = true;

  @Input() postId!: number;

  // eslint-disable-next-line accessor-pairs
  @Input() set survey(value: PostSurvey) {
    this.survey$.next(value);
  }

  canExport$ = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line accessor-pairs
  @Input() set canExport(value: boolean) {
    this.canExport$.next(value);
  }

  @ViewChild('stepper') stepper!: MatStepper;

  private destroy$ = new Subject<void>();
  private auth = inject(AuthService);
  private snackbar = inject(LazySnackBarService);
  private content = inject(ContentService);
  private http = inject(HttpClient);
  private time = inject(TimeService);
  private cd = inject(ChangeDetectorRef);

  isAuthenticated$ = this.auth.isAuthenticated$;

  emailVerified$ = this.auth.currentUser$.pipe(map((user) => user?.email_verified));

  submitting$ = new BehaviorSubject<boolean>(false);

  submitted = false;

  surveyForm!: UntypedFormGroup;
  answers!: UntypedFormArray;

  survey$ = new BehaviorSubject<PostSurvey | null>(null);

  exportUrl$ = new BehaviorSubject<string | null>(null);
  exportFileName$ = new BehaviorSubject<string | null>(null);

  constructor() {}

  ngOnInit(): void {
    this.survey$.pipe(takeUntil(this.destroy$)).subscribe((survey) => {
      this.exportUrl$.next(`/api/posts/${this.postId}/survey/export?tz=${this.time.getBrowserTimeZone()}`);

      // generate controls for each of the survey questions
      this.surveyForm = new UntypedFormGroup({
        anonymous: new UntypedFormControl(false),
        answers: new UntypedFormArray(
          survey!.questions.map(
            (question) =>
              new UntypedFormGroup({
                questionId: new UntypedFormControl(question.id),
                questionTypeId: new UntypedFormControl(question.questionTypeId),
                optionId:
                  question.questionTypeId === SurveyQuestionType.Checkbox
                    ? new UntypedFormGroup(
                        question.options.reduce(
                          (acc, option) => {
                            acc[option.id!] = new UntypedFormControl(false);
                            return acc;
                          },
                          {} as { [key: number]: UntypedFormControl }
                        ),
                        atLeastOneCheckboxCheckedValidator()
                      )
                    : new UntypedFormControl(null, [Validators.required, Validators.maxLength(1000)])
              })
          )
        )
      });

      // advance the survey if the user has selected an option on a multiple choice question
      this.surveyForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        if (
          this.stepper.selectedIndex < this.survey$.getValue()!.questions.length - 1 &&
          this.survey$.getValue()!.questions[this.stepper.selectedIndex].questionTypeId ===
            SurveyQuestionType.MultipleChoice
        ) {
          this.stepper.next();
        }
      });

      this.answers = this.surveyForm.get('answers') as UntypedFormArray;
    });
  }

  set(): void {
    console.log(this.answers);
  }

  submit(): void {
    if (!this.surveyForm.valid) {
      this.submitted = true;
      this.cd.markForCheck();
      return;
    }

    let answers: { questionId: number; optionId?: number; text?: string }[] = [];
    this.surveyForm.value.answers.forEach((answer: any) => {
      switch (answer.questionTypeId) {
        case SurveyQuestionType.Checkbox:
          answers = answers.concat(
            Object.keys(answer.optionId)
              .filter((key) => answer.optionId[key])
              .map((optionId) => ({
                questionId: answer.questionId,
                optionId: Number(optionId)
              }))
          );
          break;
        case SurveyQuestionType.MultipleChoice:
          answers.push({
            questionId: answer.questionId,
            optionId: answer.optionId
          });
          break;
        case SurveyQuestionType.ShortAnswer:
          answers.push({
            questionId: answer.questionId,
            text: answer.optionId
          });
          break;
        default:
          answers.push({
            questionId: answer.questionId,
            optionId: answer.optionId
          });
          break;
      }
    });

    this.http
      .post(`/api/posts/${this.postId}/survey`, {
        ...this.surveyForm.value,
        answers
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.submitting$.next(false);
          this.stepper.previous();
          this.cd.markForCheck();
          if (err.status === 401) {
            this.snackbar.open('You must be authenticated to take this poll');
            throw err;
          }

          this.snackbar.open('An error occurred while submitting your survey.');
          throw err;
        })
      )
      .subscribe(() => {
        this.submitting$.next(false);
        this.content.partialContentUpdate(ContentTypes.Post, this.postId, {
          completed: true,
          survey: { completed: true } as PostSurvey
        });
      });
  }

  stepSelected(event: StepperSelectionEvent): void {
    this.submitted = false;
    this.cd.markForCheck();
  }
}
