<div class="page-editor-text fx-flex">You are editing this page</div>
<button mat-button class="primary-font-color edit-header-btn cancel-btn" (click)="setEditingState(false)">Cancel</button>
<ih-animated-save-button
  mat-flat-button
  color="accent"
  [saving]="isSaving()"
  class="edit-header-btn save-btn"
  (click)="save()"
>
  <span savingText>Saving</span>
  Save
</ih-animated-save-button>
