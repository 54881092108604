import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  inject,
  Input,
  NgZone,
  OnDestroy,
  QueryList,
  Renderer2
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '@ih/services';
import { Throttle } from '@ih/utilities';
import { memoize } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { ContentCardGhostComponent } from '../content-card-ghost/content-card-ghost.component';
import { ItemWrapperComponent } from './item-wrapper.component';

@Component({
  selector: 'ih-virtual-masonry',
  standalone: true,
  imports: [CommonModule],
  template: '<ng-content></ng-content>',
  styles: [
    `
      :host {
        display: block;
        position: relative;
        width: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualMasonryComponent implements AfterViewInit, OnDestroy {
  // the list of items to display in the virtual masonry as sentinels
  @ContentChildren(ItemWrapperComponent) items!: QueryList<ItemWrapperComponent>;
  @ContentChildren(ContentCardGhostComponent) ghostItems!: QueryList<ContentCardGhostComponent>;

  // the gap between each item
  @Input() gap = 8;
  // the number of columns to display
  @Input() maxColumns = 2;

  private el = inject(ElementRef);
  private renderer = inject(Renderer2);
  private ngZone = inject(NgZone);
  private cd = inject(ChangeDetectorRef);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private content = inject(ContentService);

  // the maximum width of the card
  private readonly MAX_CARD_WIDTH = 588;
  // the minimum width of the card
  private readonly MIN_CARD_WIDTH = 304;

  private columnCount = 1;
  // the width of the masonry container
  private masonryWidth = 0;
  // the width of each item based on the number of columns and the gap
  private itemWidth = 0;
  // the intersection observer used to determine if an item is visible
  private observer: IntersectionObserver = new IntersectionObserver(
    (entries) => {
      // if the item is visible, set the visible property to true
      entries.forEach((entry) => {
        // get the item wrapper component that matches the entry target
        const itemWrapper = this.childWrappers.get(entry.target as HTMLElement);
        if (itemWrapper) {
          // set the visible property based on the intersection observer entry
          itemWrapper.visible = entry.isIntersecting;
        }
      });
    },
    // set the threshold to 0 so that the item is visible when the top/bottom of the item is at the top/bottom of the scroll container
    // set the rootMargin to 1000px so that the item is considered visible when the top/bottom of the item is 500px from the top/bottom of the scroll container
    { threshold: 0, rootMargin: '1000px 0px' }
  );

  private resizeObserver: ResizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      // find the item wrapper component that matches the entry target
      const itemWrapper = this.childWrappers.get(entry.target as HTMLElement);
      // remove the height from the cache
      delete this.content.cardHeightCache[itemWrapper!.item.contentType + '_' + itemWrapper!.item.contentId];
    });
    // recalculate the height of the item
    this.ngZone.run(() => this.layout());
  });

  // the subject used to unsubscribe from all the observables when the component is destroyed
  private destroy$ = new Subject<void>();
  // a map of the child wrappers and their corresponding element for fast lookup
  private childWrappers = new Map<HTMLElement, ItemWrapperComponent>();

  private memoizedGetColumnCount = memoize(
    (masonryWidth) => {
      if (window.innerWidth < 700) {
        return 1;
      }

      // calculate the number of columns based on the width of the masonry container and the gap
      let columns = 1;
      while (
        columns * this.MIN_CARD_WIDTH + (columns - 1) * this.gap <= masonryWidth &&
        columns <= (this.maxColumns || 2)
      ) {
        columns++;
      }

      return columns - 1;
    },
    (masonryWidth: number) => masonryWidth
  );
  private getColumnCount(): number {
    return this.memoizedGetColumnCount(this.masonryWidth);
  }

  private memoizedGetItemWidth = memoize(
    (columnCount, masonryWidth) => {
      if (columnCount === 1) {
        // if the window is less than 1100px, force the number of columns to 1
        return this.el.nativeElement.offsetWidth - 16;
      }

      return Math.min(
        this.el.nativeElement.offsetWidth - 16,
        Math.min(this.MAX_CARD_WIDTH, (masonryWidth - (columnCount - 1) * this.gap) / columnCount)
      );
    },
    (columnCount: number, masonryWidth: number) => columnCount + '_' + masonryWidth
  );
  private getItemWidth(columnCount: number): number {
    return this.memoizedGetItemWidth(columnCount, this.masonryWidth);
  }

  private updateWidths(): void {
    // calculate the width of the masonry container
    this.masonryWidth = this.el.nativeElement.offsetWidth;

    // if the window is less than 1100px, force the number of columns to 1
    // if there is only 1 item, then force the number of columns to 1
    this.columnCount = this.getColumnCount();
    // calculate the width of each item based on the number of columns and the gap
    this.itemWidth = this.getItemWidth(this.columnCount);
  }

  private cleanupObservers() {
    this.items.forEach((i) => {
      this.observer.unobserve(i.el.nativeElement);
      this.resizeObserver.unobserve(i.el.nativeElement);
      this.childWrappers.delete(i.el.nativeElement);
    });
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.updateWidths();
      requestAnimationFrame(() => {
        this.layout();
        this.observeItems();
      });

      this.items.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.cleanupObservers();
        requestAnimationFrame(() => {
          this.layout();
          this.observeItems();
        });
      });
    });
    this.updateWidths();

    requestAnimationFrame(() => {
      // Layout items
      this.layout();
      // Observe new items
      this.observeItems();
    });

    // Subscribe to changes in the items list
    this.items.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      // Clean up old observer
      this.items.forEach((i) => {
        this.observer.unobserve(i.el.nativeElement);
        this.resizeObserver.unobserve(i.el.nativeElement);
        this.childWrappers.delete(i.el.nativeElement);
      });

      requestAnimationFrame(() => {
        // Layout items
        this.layout();
        // Observe new items
        this.observeItems();
      });
    });
  }

  private resetHeightChangeListener = new Subject<void>();
  private observeItems() {
    // add each item to the observer
    this.items.forEach((i) => {
      this.observer.observe(i.el.nativeElement);
      // add the resize observer to the element
      this.resizeObserver.observe(i.el.nativeElement);
      // add the item to the map of child wrappers
      this.childWrappers.set(i.el.nativeElement, i);

      i.itemHeightChanged
        .pipe(takeUntil(this.resetHeightChangeListener), takeUntil(this.destroy$))
        .subscribe((event) => {
          // if the height of the item has changed, layout the items
          if (event.currentHeight !== i.elementHeight) {
            i.elementHeight = event.currentHeight;
            this.ngZone.run(() => this.layout());
          }
        });
    });
  }

  /**
   * Lays out the items in the virtual masonry by absolute positioning each item
   * The shortest column will have items added to it until all items are added
   * The height of the grid is set to the height of the tallest column
   * The width of each item is set to the width of the masonry container divided by the number of columns
   * The position of each item is set to the position of the column based on the width of the item and the gap
   */
  private layout() {
    // create an array of column heights with the length of the number of columns
    const columnHeights = new Array(this.columnCount).fill(0);

    // calculate the position of each column based on the width of the item and the gap
    // the last column does not have a gap
    // card widths are capped at maxCardWidth. if column count * maxCardWidth is less than the masonry width,
    // then move the position of the columns into the center so that the gap is even on both sides, while still maintaining the gap between columns
    // if column count * maxCardWidth is greater than the masonry width, then move the position of the columns to the left so that the gap is even on both sides
    const totalCardWidth = this.columnCount * this.MAX_CARD_WIDTH;
    // the total gap width is the number of columns minus 1 times the gap
    const totalGapWidth = (this.columnCount - 1) * this.gap;

    const columnPositions =
      totalCardWidth + totalGapWidth < this.masonryWidth
        ? new Array(this.columnCount)
            .fill(0)
            .map(
              (_, i) => (this.masonryWidth - totalCardWidth - totalGapWidth) / 2 + i * (this.MAX_CARD_WIDTH + this.gap)
            )
        : new Array(this.columnCount).fill(0).map((_, i) => i * this.itemWidth + i * this.gap);

    // loop through each item and set the position of the item to the shortest column
    this.items.forEach((child) => {
      // if the height of the item is in the cache, and the elementHeight is 0 use it
      if (
        this.content.cardHeightCache[child.item.contentType + '_' + child.item.contentId] &&
        child.elementHeight === 0
      ) {
        child.elementHeight = this.content.cardHeightCache[child.item.contentType + '_' + child.item.contentId];
      }
      // find the shortest column
      const shortestColumnIndex = columnHeights.indexOf(Math.min(...columnHeights));

      // Round the height calculations
      const itemHeight = Math.round(child.elementHeight);
      const position = Math.round(columnHeights[shortestColumnIndex]);

      // set the position of the item to the shortest column
      // batch the style changes to prevent reflows
      // maintain the height of the item so intersection observer works correctly when the item is hidden
      this.renderer.setAttribute(
        child.el.nativeElement,
        'style',
        `position:absolute; transform: translate(${this.columnCount > 1 ? columnPositions[shortestColumnIndex] : 8}px,${position}px ); width: ${this.itemWidth}px; margin: 0px; height: ${itemHeight}px;`
      );

      this.content.cardHeightCache[child.item.contentType + '_' + child.item.contentId] = child.elementHeight;

      // add the height of the item to the shortest column
      columnHeights[shortestColumnIndex] += child.elementHeight + this.gap;
    });

    // position the ghost items at the end of the masonry
    this.ghostItems.forEach((child) => {
      // find the shortest column
      const shortestColumnIndex = columnHeights.indexOf(Math.min(...columnHeights));

      // set the position of the item to the shortest column
      // batch the style changes to prevent reflows
      this.renderer.setAttribute(
        child.el.nativeElement,
        'style',
        `position: absolute; top: ${columnHeights[shortestColumnIndex]}px; left: ${
          this.columnCount > 1 ? columnPositions[shortestColumnIndex] : 8
        }px; width: ${this.itemWidth}px; margin: 0px;`
      );

      // figure out the height based on the width which is the same as the card width
      // height is a ratio of 96/133
      const height = (171 / 229) * this.itemWidth;

      // add the height of the item to the shortest column
      columnHeights[shortestColumnIndex] += height + this.gap;
    });

    // Set the height of the grid to the height of the tallest column
    this.renderer.setAttribute(this.el.nativeElement, 'style', `height: ${Math.max(...columnHeights)}px;`);

    this.cd.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  @Throttle(100)
  onResize() {
    this.ngZone.runOutsideAngular(() => {
      this.updateWidths();
      this.content.cardHeightCache = {};

      requestAnimationFrame(() => {
        this.layout();
      });
    });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
    this.resizeObserver.disconnect();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
