import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ContentItem, LocationOptions } from '@ih/interfaces';
import { BehaviorSubject } from 'rxjs';
import { LocationDetailComponent } from '../location-detail/location-detail.component';

@Component({
  selector: 'ih-post-location-detail',
  templateUrl: './post-location-detail.component.html',
  styleUrls: ['./post-location-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, MatButtonModule, LocationDetailComponent]
})
export class PostLocationDetailComponent {
  @HostBinding('class.ih-post-location-detail') hostClass = true;

  location$ = new BehaviorSubject<LocationOptions | null>(null);
  // eslint-disable-next-line accessor-pairs
  @Input() set post(value: ContentItem) {
    if (value.locationLat && value.locationLng) {
      this.location$.next({
        latLng: { lat: value.locationLat, lng: value.locationLng },
        zoom: value.locationZoom ?? 4,
        mapTypeId: value.locationMapType as google.maps.MapTypeId
      });
    }
  }
}
