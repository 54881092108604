import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AdminBaseComponent } from '@ih/admin-base';
import { ENVIRONMENT_PRODUCT } from '@ih/constants';
import { ContentListItemComponent } from '@ih/content-list-item';
import { ContentOption, ContentState, ContentStatus, ContentTypes, Products } from '@ih/enums';
import { AllContentFilterChipsDirective, FilterChipsComponent } from '@ih/filter-chips';
import { ContentListItem, ImageInfo, ListFilterItem, ListFilterItemValue, Person } from '@ih/interfaces';
import { LazyDialogService } from '@ih/lazy-dialog';
import { PostEditorService } from '@ih/post-editor';
import {
  AuthService,
  ChannelService,
  ClipboardService,
  ContentService,
  LazySnackBarService,
  SecurityService
} from '@ih/services';
import { copyTextWithMessage, getChannelMdiIconSvg } from '@ih/utilities';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  BehaviorSubject,
  Subject,
  catchError,
  combineLatest,
  debounceTime,
  firstValueFrom,
  switchMap,
  take,
  takeUntil
} from 'rxjs';
import { ContentViewersDialogComponent } from '../content-viewers-dialog/content-viewers-dialog.component';

@Component({
  selector: 'ih-submitted-content-list',
  standalone: true,
  imports: [
    AsyncPipe,
    NgForOf,
    NgIf,
    RouterModule,

    MatButtonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    ScrollingModule,

    AdminBaseComponent,
    AllContentFilterChipsDirective,
    ContentListItemComponent,
    FilterChipsComponent,

    ContentViewersDialogComponent,
    InfiniteScrollModule
  ],
  templateUrl: './submitted-content-list.component.html',
  styleUrl: './submitted-content-list.component.scss'
})
export class SubmittedContentComponent implements OnInit, OnDestroy {
  @HostListener('class.ih-submitted-content-list') hostClass = true;

  @ViewChild(ContentListItemComponent, { read: ElementRef }) ihContentListItem!: ElementRef;

  private http = inject(HttpClient);
  private snackbar = inject(LazySnackBarService);
  private auth = inject(AuthService);
  private security = inject(SecurityService);
  private route = inject(ActivatedRoute);
  private content = inject(ContentService);
  private lazyDialog = inject(LazyDialogService);
  private clipboard = inject(ClipboardService);
  private channel = inject(ChannelService);
  private postEditor = inject(PostEditorService);
  private product = inject(ENVIRONMENT_PRODUCT);

  private skip$ = new BehaviorSubject<number>(0);
  private filters$ = new BehaviorSubject<ListFilterItem[]>([]);

  private destroy$ = new Subject<void>();

  items$ = new BehaviorSubject<ContentListItem[]>([]);
  noMore$ = new BehaviorSubject<boolean>(false);

  editingOrder$ = new BehaviorSubject<boolean>(false);

  loading$ = new BehaviorSubject<boolean>(true);

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.skip$.next(0);
      this.filters$.next([]);
    });

    combineLatest([this.skip$, this.filters$])
      .pipe(
        debounceTime(100),
        switchMap(([skip, filters]) => {
          this.loading$.next(true);

          const params: { [key: string]: string } = {
            skip: skip.toString(),
            ugc: 'true',
            take: '40'
          };
          filters.forEach((filter) => {
            params[filter.queryParam] = filter.multiple
              ? JSON.stringify((filter.query as ListFilterItemValue[]).map((v) => v.value))
              : ((filter.query as ListFilterItemValue).value as string);
          });

          return this.http.get<ContentListItem[]>('/api/content', {
            params
          });
        }),
        catchError((err, caught) => {
          this.loading$.next(false);

          this.snackbar.open('Error loading posts', 'TRY AGAIN').then((ref) => ref.onAction().subscribe(() => caught));

          throw err;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((items) => {
        this.loading$.next(false);

        // set status on each item based on properties of the item
        // also set channel svgs
        items.forEach((item) => {
          item.channels.forEach((channel) => {
            channel.svg = getChannelMdiIconSvg(channel.joinType);
          });

          item.canEdit = this.security.canItem(
            ContentOption.Edit,
            item.channels.map((c) => c.channelId),
            this.auth.currentUser$.value!.campaign_user_id === item.createdBy?.authorId
          );

          item.status = this.content.getContentStatus(item.active, item.archived, item.startDate!, item.endDate!);
        });
        this.items$.next([...this.items$.value, ...items]);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  loadMore(): void {
    if (this.loading$.value === true) {
      return;
    }

    this.skip$.next(this.skip$.value + 40);
  }

  contentItemHash(index: number, item: ContentListItem): string {
    return 'event' + item.contentId.toString() + item.updatedAt?.toString();
  }

  filterChanged(filters: ListFilterItem[]): void {
    this.filters$.next(filters);
    this.skip$.next(0);
    this.items$.next([]);
  }

  publish(item: ContentListItem) {
    const imperativeTense = item.active ? 'Unpublish' : 'Publish';
    const presentTense = item.active ? 'Unpublishing' : 'Publishing';
    const pastTense = item.active ? 'Unpublished' : 'Published';
    const status = item.active ? ContentStatus.Draft : ContentStatus.Published;

    this.updateState(item, status, imperativeTense, presentTense, pastTense);
  }

  deleteItem(item: ContentListItem) {
    const imperativeTense = item.archived ? 'Restore' : 'Delete';
    const presentTense = item.archived ? 'Restoring' : 'Deleting';
    const pastTense = item.archived ? 'Restored' : 'Deleted';
    const status = item.archived ? ContentStatus.Draft : ContentStatus.Deleted;

    this.updateState(item, status, imperativeTense, presentTense, pastTense);
  }

  private updateState(
    item: ContentListItem,
    status: ContentStatus,
    imperativeTense: string,
    presentTense: string,
    pastTense: string
  ) {
    this.snackbar.open(`${presentTense} please wait...`, undefined, {
      duration: 60000
    });

    let state: ContentState;
    let active = false;
    let archived = false;
    switch (status) {
      case ContentStatus.Draft:
        state = ContentState.Draft;
        break;
      case ContentStatus.Published:
        state = ContentState.Published;
        active = true;
        break;
      case ContentStatus.Deleted:
        state = ContentState.Deleted;
        archived = true;
        break;
      default:
        throw new Error('Invalid state');
    }
    this.http
      .put(`/api/${item.contentType}/${item.contentId}/state`, state)
      .pipe(
        catchError((err, caught) => {
          this.snackbar.dismiss();
          this.snackbar
            .open(
              `Something went wrong while we tried to ${imperativeTense.toLocaleLowerCase()} that. Please contact us`,
              'TRY AGAIN'
            )
            .then((ref) => ref.onAction().subscribe(() => setTimeout(() => caught)));

          throw err;
        })
      )
      .subscribe(() => {
        this.snackbar.open(pastTense);
        // update item in list immutably
        const items = this.items$.value;
        const index = items.findIndex((i) => i.contentId === item.contentId);
        items[index] = { ...items[index], status, active, archived };
        this.items$.next([...items]);
      });
  }

  clone(item: ContentListItem) {
    this.snackbar.open('Cloning please wait...', undefined, {
      duration: 60000
    });
    this.http.post(`/api/${item.contentType}/${item.contentId}/clone`, {}).subscribe({
      next: () => {
        this.snackbar.open('Cloning process complete');
        this.skip$.next(0);
        this.items$.next([]);
      },
      error: (resp) => {
        this.snackbar.dismiss();
        console.error('Unable to clone', resp);
        this.snackbar
          .open('Something went wrong while we tried to clone that. Please contact us', 'TRY AGAIN')
          .then((ref) => ref.onAction().subscribe(() => setTimeout(() => this.clone(item))));
      }
    });
  }

  copyContentLink(item: ContentListItem): void {
    const message = copyTextWithMessage(
      {
        path: 'events/',
        id: `${item.contentId}/`,
        slug: `${item.slug ? item.slug : ''}`
      },
      'url',
      false
    );
    this.clipboard.copyTextToClipboard(message.contentToCopy).then(
      () => {
        this.snackbar.open(message.successMessage);
      },
      () => {
        this.snackbar.open(message.failMessage);
      }
    );
  }

  async addEditPost(item?: ContentListItem): Promise<Promise<void>> {
    const homeChannel =
      this.product !== Products.Builder ? await firstValueFrom(this.channel.homeChannel$.pipe(take(1))) : null;

    const postEditorDialog = await this.postEditor.open({
      instantPublish: true,
      landingPage: this.route.snapshot.data['landingPage'],
      // fall back to using window.campaign.homeChannel for the builder
      channelIds: item?.channels.map((c) => c.channelId) ?? [
        homeChannel?.channelId ?? window.campaign.homeChannel.channelId
      ],
      postId: item?.contentId
    });

    postEditorDialog.postComplete.subscribe((post) => {
      // if postId is null, then we are creating a new post and must refresh
      if (!post.postId) {
        this.skip$.next(0);
        this.items$.next([]);
        return;
      }
      // if postId is not null, then we are editing an existing post and must update the post in the list
      const items = this.items$.value;
      const index = items.findIndex((i) => i.contentId === post.postId);
      const me: Person = {
        authorId: this.auth.currentUser$.value!.campaign_user_id,
        firstName: this.auth.currentUser$.value!.first_name,
        lastName: this.auth.currentUser$.value!.last_name,
        fullName: this.auth.currentUser$.value!.fullName,
        email: this.auth.currentUser$.value!.email,
        handle: this.auth.currentUser$.value!.handle,
        image: {
          url: this.auth.currentUser$.value!.profileImage?.url ?? '',
          color: this.auth.currentUser$.value!.profileImage.color ?? '',
          blurHash: this.auth.currentUser$.value!.profileImage.blurHash ?? '',
          stockPhoto: this.auth.currentUser$.value!.profileImage.stockPhoto ?? undefined,
          cropData: this.auth.currentUser$.value!.profileImage.cropData ?? undefined
        }
      };
      // calculate the status of the post
      const status = this.content.getContentStatus(
        post.active ?? false,
        post.deleted ?? false,
        post.startDate ?? null,
        post.endDate ?? null
      );

      let image: ImageInfo | null = null;
      switch (post.mediaType) {
        case 'image':
          image = {
            url: post.image?.url ?? '',
            color: post.image?.color ?? '',
            blurHash: post.image?.blurHash ?? '',
            stockPhoto: post.image?.stockPhoto ?? undefined,
            cropData: post.image?.cropData!
          };
          break;
        case 'video':
          image = {
            url: post.video?.image?.url ?? '',
            color: post.video?.image?.color ?? '',
            blurHash: post.video?.image?.blurHash ?? '',
            stockPhoto: post.video?.image?.stockPhoto ?? undefined,
            cropData: post.video?.image?.cropData!
          };
          break;
        case 'custom':
          image = {
            url: post.embed?.image?.url ?? '',
            color: post.embed?.image?.color ?? '',
            blurHash: post.embed?.image?.blurHash ?? '',
            stockPhoto: post.embed?.image?.stockPhoto ?? undefined,
            cropData: post.embed?.image?.cropData!
          };
          break;
      }

      items[index] = {
        ...items[index],
        title: post.title ?? '',
        status,
        // convert post.image (MediaCropImage) to ContentListItem.image (ImageInfo)
        mediaType: post.mediaType,
        image,
        channels: post.channels.map((c) => ({
          channelId: c.channelId,
          name: c.name,
          handle: c.handle,
          everyone: c.everyone ?? false,
          visibleForGuests: c.visibleForGuests,
          contentVisibleForGuests: c.contentVisibleForGuests,
          joinType: c.joinType,
          svg: getChannelMdiIconSvg(c.joinType)
        })),
        modules: post.modules,
        active: post.active ?? false,
        updatedAt: new Date(),
        updatedBy: me,
        archived: post.deleted ?? false,
        startDate: post.startDate ?? undefined,
        endDate: post.endDate ?? undefined,
        landingPage: post.landingPage
      };

      this.items$.next([...items]);
    });
  }

  async showEditor(item: ContentListItem): Promise<void> {
    switch (item?.contentType) {
      case ContentTypes.Post:
        await this.addEditPost(item);
        break;
    }
  }

  async showViewers(item: ContentListItem) {
    const dialog = await this.lazyDialog.getDialogService();

    dialog.open(ContentViewersDialogComponent, {
      data: { contentType: item.contentType, contentId: item.contentId },
      closeOnNavigation: true,
      panelClass: ['content-viewers-dialog', 'basic-dialog'],
      maxWidth: undefined,
      width: undefined,
      minHeight: undefined
    });
  }

  trackByFn(index: number, item: ContentListItem): string {
    return item.contentId.toString();
  }
}
