import { Component, HostBinding, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AnimatedSaveButtonComponent } from '@ih/animated-save-button';
import { fadeInOut } from '@ih/animations';
import { EditPageService } from '../services/edit-page.service';

@Component({
  selector: 'ih-edit-page-header',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, AnimatedSaveButtonComponent],
  templateUrl: './edit-page-header.component.html',
  styleUrl: './edit-page-header.component.scss',
  animations: [fadeInOut]
})
export class EditPageHeaderComponent {
  @HostBinding('class.hide-page-header') get hidePageHeader() {
    return !this.isEditingPage();
  }

  private editPage = inject(EditPageService);

  isEditingPage = this.editPage.isEditing;
  isSaving = this.editPage.isSaving;

  setEditingState(editing: boolean): void {
    this.editPage.setEditing(editing);
  }

  save(): void {
    this.editPage.save();
  }
}
