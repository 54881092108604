import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  computed,
  inject,
  signal
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AuthorInfoComponent } from '@ih/author-info';
import { ENVIRONMENT_PRODUCT, EVENT_DATE_CARD_FORMAT } from '@ih/constants';
import { EmojiPipe } from '@ih/emoji';
import { ContentTypes, Products } from '@ih/enums';
import { ImageComponent } from '@ih/image';
import { BaseClientConfig, ContentListItem } from '@ih/interfaces';
import { CompactNumberPipe, HtmlEncodeSimplePipe, LbToBrPipe, UserViewPipe } from '@ih/pipes';
import { SafePipe } from '@ih/safe-pipe';
import { ConfigService } from '@ih/services';
import { getAlphaClass, getAppOriginFromConfig } from '@ih/utilities';
import { timer } from 'rxjs';

@Component({
  selector: 'ih-content-list-item',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,

    MatButtonModule,
    MatChipsModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,

    AuthorInfoComponent,
    CompactNumberPipe,
    EmojiPipe,
    HtmlEncodeSimplePipe,
    ImageComponent,
    LbToBrPipe,

    SafePipe,

    UserViewPipe
  ],
  templateUrl: './content-list-item.component.html',
  styleUrls: ['./content-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentListItemComponent {
  @HostBinding('class.ih-content-list-item') hostClass = true;
  @HostBinding('class.editing-order')
  @Input()
  editingOrder = false;

  private product = inject(ENVIRONMENT_PRODUCT);

  private configService = inject(ConfigService) as ConfigService<BaseClientConfig>;

  get alphaClass(): string {
    return `alpha-${getAlphaClass(this.item?.title || '💬')}`;
  }

  ContentTypes = ContentTypes;

  @Output()
  editClicked = new EventEmitter<ContentListItem>();

  @Output()
  publishClicked = new EventEmitter<ContentListItem>();

  @Output()
  deleteClicked = new EventEmitter<ContentListItem>();

  @Output()
  cloneClicked = new EventEmitter<ContentListItem>();

  @Output()
  copyContentLinkClicked = new EventEmitter<ContentListItem>();

  @Output()
  showViewersClicked = new EventEmitter<ContentListItem>();

  @Output()
  showCtaClicked = new EventEmitter<ContentListItem>();

  @Output()
  showVolunteersClicked = new EventEmitter<ContentListItem>();

  @Output()
  showTicketsClicked = new EventEmitter<ContentListItem>();

  @Output()
  showResponsesClicked = new EventEmitter<ContentListItem>();

  @Output()
  pinClicked = new EventEmitter<ContentListItem>();

  @Input() showContentType = false;

  _item = signal<ContentListItem | undefined>(undefined);
  @Input()
  get item(): ContentListItem | undefined {
    return this._item();
  }

  set item(value: ContentListItem) {
    this._item.set(value);
  }

  startDateExpired = computed(() => {
    const item = this._item();
    return item && item.startDate && new Date(item.startDate) <= new Date();
  });

  config = toSignal(this.configService.config$);

  itemLink = computed(() => {
    const config = this.config()!;
    const item = this._item();

    if (!item) {
      return undefined;
    }

    let urlPrefix = '';
    if (this.isBuilder) {
      // links need to be absolute
      urlPrefix = getAppOriginFromConfig(config);
    }

    return item && item.contentType !== ContentTypes.Slider
      ? `${urlPrefix}/${item.contentType}/${item.contentId}${item.slug ? '/' + item.slug : ''}`
      : undefined;
  });

  title = computed(() => {
    const item = this._item();
    if (item?.title) {
      return item.title;
    }

    switch (item?.contentType) {
      case ContentTypes.Slider:
        return 'Slider #' + item.contentId;
      case ContentTypes.Post:
        return 'Post #' + item.contentId;
      default:
        throw new Error(`Title fallback not configured. Unknown content type: ${item?.contentType}`);
    }
  });

  channels = computed(() => {
    const config = this.config()!;
    const item = this._item();
    return item?.channels.map((channel) => {
      const href =
        channel.href ||
        (channel.everyone
          ? `${getAppOriginFromConfig(config)}/home`
          : `${getAppOriginFromConfig(config)}/c/${channel.handle || channel.channelId}/feed`);
      return { ...channel, href };
    });
  });

  EVENT_DATE_CARD_FORMAT = EVENT_DATE_CARD_FORMAT;

  isBuilder = this.product === Products.Builder;

  timer = toSignal(timer(0, 60 * 1000));

  // update the isPublished state every minute
  isPublished = computed(() => {
    // force a refresh every minute
    this.timer();

    const item = this._item();
    return item && item.active && (!item.startDate || new Date(item.startDate) <= new Date());
  });

  isExpired = computed(() => {
    // force a refresh every minute
    this.timer();

    const item = this._item();
    return item && item.endDate && new Date(item.endDate) <= new Date();
  });

  limitTo = 3;

  showMoreChannels(): void {
    this.limitTo = this.item!.channels.length;
  }

  getContentAlias(contentType: ContentTypes, landingPage?: boolean): string {
    switch (contentType) {
      case ContentTypes.Slider:
        return 'Slider';
      case ContentTypes.Post:
        if (landingPage) {
          return 'Page';
        }
        return 'Post';
      case ContentTypes.Event:
        return 'Event';
      default:
        throw new Error(`Unknown content type: ${contentType}`);
    }
  }
}
