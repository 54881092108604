<ih-content-card-ghost class="content-card"></ih-content-card-ghost>
<a [href]="itemUrl" target="_blank" (click)="clicked($event)" trackClick="Content card image clicked">
  <div
    class="card-image"
    *ngIf="showImage || showAlpha"
    [ngClass]="{ 'has-image': showImage }"
    [style.aspect-ratio]="aspectRatio$ | async"
  >
    @if (item.locked) {
      <div class="locked-post-overlay">
        <mat-icon svgIcon="lock" class="locked-post-overlay-icon"></mat-icon>
      </div>
    }
    <div
      class="alphanumeric alphanumeric-card"
      [ngClass]="alphaClass"
      [style.min-height]="alphaMinHeight"
      *ngIf="item.mediaType === 'alpha'"
    >
      <span
        [innerHtml]="
          item.title || '💬' | htmlEncodeSimple | lbToBr | emoji: 108 | highlight: isSearchResult : 'ins' | safe: 'html'
        "
      ></span>
    </div>
    <ih-image
      #cardImage
      *ngIf="showImage"
      [ngClass]="{
        'yt-thumb-clip': item.mediaType === 'video' && item.videoProviderId === 1 && item.contentType !== 'posts'
      }"
      [imageInfo]="item.image!"
      [fallback]="item.image!.url"
      [aspectRatio]="!item.image?.cropData ? 9 / 16 : undefined"
      sizes="(max-width: 816px) calc(100vw - 16px), (max-width:1100px) 784px, (max-width: 1210px) calc(calc(100vw - 40px) / 2), 580px"
      alt="image for {{ item.title! | characters: 40 }}"
    >
    </ih-image>
    <span *ngIf="item.mediaType === 'video' && item.duration" class="label-video">{{ item.duration | duration }}</span>
  </div>
  <mat-card-content>
    <div class="card-content-title-row">
      @if (canPublish() || canEdit()) {
        <!-- 3 dots menu -->
        <div class="content-card-menu">
          <button
            mat-icon-button
            (click)="$event.stopPropagation(); $event.preventDefault()"
            class="content-card-menu-button"
            [matMenuTriggerFor]="postMenu"
            trackClick="Post menu button clicked"
          >
            <mat-icon class="content-menu-icon" svgIcon="dots-vertical"></mat-icon>
          </button>
          <mat-menu #postMenu="matMenu" xPosition="before" yPosition="above" class="content-menu">
            <!-- Unpublish -->
            <button mat-menu-item (click)="unpublish()" [disabled]="!canPublish()">
              <mat-icon svgIcon="publish-off"></mat-icon>
              <span> Unpublish </span>
            </button>

            @if (item.contentType !== ContentTypes.Event) {
              <!-- PIN -->
              <button mat-menu-item [disabled]="!canPublish()" (click)="togglePin()">
                <mat-icon [svgIcon]="item!.pinned ? 'pin-outline' : 'pin'"> </mat-icon>
                <span>{{ item!.pinned ? 'Unpin' : 'Pin' }}</span>
              </button>

              <!-- EDIT -->
              <button mat-menu-item (click)="edit()" [disabled]="!canEdit()">
                <mat-icon>
                  <svg viewBox="0 0 24 24">
                    <path
                      d="M3,17V21H7L17.16,10.84L13.16,6.84L3,17M21.41,9.59C21.79,9.21 22,8.73 22,8.24C22,7.75 21.79,7.27 21.41,6.89L19.11,4.59C18.73,4.21 18.25,4 17.76,4C17.27,4 16.79,4.21 16.41,4.59L15,6L18,9L19.41,7.59L21.41,9.59M20.84,5.42L18.58,3.16L16.58,5.16L18.84,7.42L20.84,5.42Z"
                    />
                  </svg>
                </mat-icon>
                <span>Edit</span>
              </button>
            }
          </mat-menu>
        </div>
      }
      @if (item.locked) {
        <div class="locked-post-label" [inProgress]="joiningChannel()">
          <span class="locked-text">Join to unlock</span>
          <mat-icon svgIcon="lock" class="locked-post-icon"></mat-icon>
        </div>
      }
      <span *ngIf="item.title" class="mat-headline-5" [ngClass]="{ 'long-title': item.title!.length > 250 }">
        <ng-container *ngIf="item.mediaType !== 'alpha'">
          <span
            class="headline-text"
            dir="auto"
            [innerHtml]="
              item.title
                | characters: (showMore ? 1000 : 350)
                | htmlEncodeSimple
                | lbToBr
                | emoji
                | highlight: isSearchResult
                | safe: 'html'
            "
          >
          </span>
          <span
            role="button"
            tabindex="0"
            class="btn-show-more"
            (click)="toggleShowMore($event)"
            (keypress)="toggleShowMore($event)"
            *ngIf="item.title.length > 350 && !showMore"
            >...<u>read more</u></span
          >
        </ng-container>
      </span>
    </div>

    <div class="card-post-info-row fx-layout-row fx-layout-align-start-end">
      <div>
        <div class="mat-subhead" [ngClass]="{ wrap: (item.contentType === 'events' && !concluded) || shouldWrap }">
          <!-- EVENT DATE -->
          <span class="event-date" *ngIf="item.contentType === 'events'">
            <ng-container *ngIf="!concluded">
              <ng-container *ngIf="!started">
                Starts&nbsp;{{ item.eventStartDate | date: EVENT_DATE_CARD_FORMAT }} {{ item.timeZoneShortName }}
              </ng-container>
              <ng-container *ngIf="started">
                Started&nbsp;{{ item.eventStartDate | date: EVENT_DATE_FORMAT }}
                {{ item.timeZoneShortName }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="concluded">Concluded</ng-container>
          </span>

          <!-- TIME AGO -->
          <span *ngIf="item.contentType !== 'events'">
            <ih-time-ago class="time-ago" [value]="item.postedDate!" (click)="swallowClick($event)"></ih-time-ago>
          </span>

          <!-- COMMENT COUNT -->
          <span class="comment-count" *ngIf="item.commentCount > 0">
            <mat-icon class="comment-bullet">
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z"
                />
              </svg>
            </mat-icon>
            <span>{{ item.commentCount }} {{ commentText }}</span>
          </span>
        </div>

        <!-- CARD POST LABEL -->
        <span
          *ngIf="item.contentType !== 'posts' || postType"
          class="card-post-label {{ item.contentType }}"
          role="button"
          tabindex="0"
          (click)="changeFilter()"
          (keypress)="changeFilter()"
          [ngStyle]="{ color: postType && postType.foreColor, 'background-color': postType && postType.backColor }"
        >
          {{ (postType && postType.name) || itemLabel }}</span
        >
      </div>

      <span class="flex"></span>

      <div class="icon-container">
        <mat-icon class="pin-icon" svgIcon="pin-outline" title="pinned post" *ngIf="item.pinned"></mat-icon>
        <mat-icon
          class="link-icon"
          svgIcon="open-in-new"
          title="linked post"
          *ngIf="item.mediaType === 'link'"
        ></mat-icon>
        <!-- HEART ICON -->
        <ih-heart
          *ngIf="!isSearchResult && item.mediaType !== 'link'"
          (voted)="vote()"
          (click)="click($event)"
          [isUpvoted]="item.myVote ?? false"
          [upvotes]="item.upvotes"
          trackClick="Share controls Heart button clicked"
          [trackProperties]="{ url: itemUrl }"
        ></ih-heart>
      </div>
    </div>
  </mat-card-content>
</a>
