<ng-container *ngIf="{ item: _item()!, isPublished: isPublished(), isExpired: isExpired() } as data">
  <!-- Image card -->
  <ih-image
    class="content-card-image"
    *ngIf="data.item.image"
    [imageInfo]="data.item.image"
    [aspectRatio]="9 / 16"
  ></ih-image>

  <!-- None card -->
  <div
    class="content-card-image nocard-card fx-layout-align-center-center"
    *ngIf="
      data.item.mediaType == 'nocard' ||
      (data.item.mediaType === 'link' && !data.item.image) ||
      (data.item.mediaType === 'image' && !data.item.image)
    "
  >
    <div class="nocard-icon-container">
      <svg class="nocard-icon" viewBox="0 0 24 24">
        <path
          d="M9.2 6L7.2 4H20C21.11 4 22 4.89 22 6V18C22 18.24 21.96 18.47 21.88 18.68L20 16.8V6H9.2M22.11 21.46L20.84 22.73L18.11 20H4C2.9 20 2 19.11 2 18V6C2 5.42 2.25 4.9 2.65 4.54L1.11 3L2.39 1.73L6.66 6L18.65 18H18.66L20.57 19.91L20.56 19.91L22.11 21.46M16.11 18L4.11 6H4V18H16.11Z"
        />
      </svg>
    </div>
  </div>

  <!-- Custom card if no image -->
  <div
    class="content-card-image custom-card fx-layout-align-center-center"
    *ngIf="!data.item.image && data.item.mediaType == 'custom'"
  >
    <div class="custom-icon-container">
      <svg class="custom-icon" viewBox="0 0 24 24">
        <path
          d="M14.6,16.6L19.2,12L14.6,7.4L16,6L22,12L16,18L14.6,16.6M9.4,16.6L4.8,12L9.4,7.4L8,6L2,12L8,18L9.4,16.6Z"
        />
      </svg>
    </div>
  </div>

  <!-- Video card if no image -->
  <div
    class="content-card-image video-card fx-layout-align-center-center"
    *ngIf="!data.item.image && data.item.mediaType == 'video'"
  >
    <div class="video-icon-container">
      <svg class="video-icon" viewBox="0 0 24 24">
        <title>video-outline</title>
        <path
          d="M15,8V16H5V8H15M16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5V7A1,1 0 0,0 16,6Z"
        />
      </svg>
    </div>
  </div>

  <!-- Alpha card -->
  <div
    class="content-card-image alphanumeric alphanumeric-card"
    *ngIf="data.item.mediaType == 'alpha' || data.item.mediaType == 'none'"
    [ngClass]="alphaClass"
  >
    <span [innerHtml]="data.item.title || '💬' | htmlEncodeSimple | lbToBr | emoji | safe"></span>
  </div>

  <div class="content-card-info-container fx-flex">
    <div class="labels-container fx-layout-column fx-layout-align-start-end">
      @if (item!.ugc) {
        <div
          class="user-submitted-label fx-layout-row fx-layout-align-start-center"
          matTooltip="This is submitted content - Please review and edit before publishing."
        >
          <mat-icon class="user-submitted-icon" svgIcon="alert"></mat-icon>
          <span>User submitted</span>
        </div>
      }
      <!-- Status -->
      <div class="content-publish-status" [ngClass]="data.item.status">
        {{ data.item.status }}
      </div>
      @if (showContentType) {
        <!-- Type -->
        <div
          class="content-type-label"
          [class.post]="data.item.contentType === ContentTypes.Post && !data.item.landingPage"
          [class.page]="data.item.contentType === ContentTypes.Post && data.item.landingPage"
          [class.event]="data.item.contentType === ContentTypes.Event"
          [class.slider]="data.item.contentType === ContentTypes.Slider"
        >
          {{ getContentAlias(data.item.contentType, data.item.landingPage) }}
        </div>
      }
    </div>
    <div class="content-card-header fx-layout-row fx-layout-align-start-start">
      <!-- Title -->
      <ng-container *ngIf="{ itemLink: itemLink() } as titleOpts">
        <a
          *ngIf="titleOpts.itemLink && isBuilder"
          class="content-card-link"
          [href]="titleOpts.itemLink"
          target="_blank"
        >
          <span
            class="content-card-title"
            [class.untitled]="!data.item.title"
            [innerHtml]="title()! | htmlEncodeSimple | emoji | safe"
          ></span>
          <mat-icon class="open-in-new-icon">
            <svg id="open-in-new" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z"
              />
            </svg>
          </mat-icon>
        </a>
        <a *ngIf="titleOpts.itemLink && !isBuilder" class="content-card-link" [routerLink]="titleOpts.itemLink">
          <span
            class="content-card-title"
            [class.untitled]="!data.item.title"
            [innerHtml]="title()! | htmlEncodeSimple | emoji | safe"
          ></span>
        </a>
        <span
          *ngIf="!titleOpts.itemLink"
          class="content-card-title"
          [class.untitled]="!data.item.title"
          [innerHtml]="title()! | htmlEncodeSimple | emoji | safe"
        ></span>
      </ng-container>
    </div>

    <!-- Publish and Expire dates -->
    <div class="content-dates-container fx-layout-column" *ngIf="!editingOrder">
      <div class="content-start-date fx-layout-row fx-layout-wrap" *ngIf="data.item.startDate || data.isPublished">
        <div class="content-card-label">Publish<span *ngIf="data.isPublished">ed</span>:&nbsp;</div>
        <span class="start-date">
          {{ data.item.startDate ?? data.item.publishedAt | date: EVENT_DATE_CARD_FORMAT }}
        </span>
      </div>
      <div class="content-expire-date fx-layout-row fx-layout-wrap" *ngIf="data.item.endDate">
        <div class="content-card-label">
          Expire<span *ngIf="!data.isExpired">s</span><span *ngIf="data.isExpired">d</span>:&nbsp;
        </div>
        <span class="expire-date">
          {{ data.item.endDate | date: EVENT_DATE_CARD_FORMAT }}
        </span>
      </div>
      <div class="content-updated-date fx-layout-row fx-layout-wrap" *ngIf="data.item.updatedAt">
        <div class="content-card-label">Updated:&nbsp;</div>
        <ih-author-info [author]="data.item.updatedBy!" [thumbSize]="14">
          <span class="updated-by-author-name">{{ data.item.updatedBy?.fullName }}</span>
        </ih-author-info>
        <span>&nbsp;-&nbsp;</span>
        <span class="updated-date">
          {{ data.item.updatedAt | date: EVENT_DATE_CARD_FORMAT }}
        </span>
      </div>
      <div class="content-created-date fx-layout-row fx-layout-wrap" *ngIf="data.item.createdAt">
        <div class="content-card-label">Created:&nbsp;</div>
        <ih-author-info [author]="data.item.createdBy" [thumbSize]="14">
          <span class="created-by-author-name">{{ data.item.createdBy?.fullName ?? 'unknown' }}</span>
        </ih-author-info>
        <span>&nbsp;-&nbsp;</span>
        <span class="created-date">
          {{ data.item.createdAt | date: EVENT_DATE_CARD_FORMAT }}
        </span>
      </div>
      <div class="content-link fx-layout-row" *ngIf="data.item.link?.url">
        <div class="content-card-label">Linked to:&nbsp;</div>
        <a class="content-link-anchor fx-layout-row" [href]="data.item.link!.url" target="_blank">
          <ng-container *ngIf="data.item.link!.type">
            <span class="content-link-type">{{ data.item.link!.type }}</span>
            <ng-container *ngIf="data.item.link!.title!">
              <span>&nbsp;-&nbsp;</span>
              <span class="content-link-title">{{ data.item.link!.title }}</span>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!data.item.link!.type">
            {{ data.item.link!.url }}
          </ng-container>
        </a>
      </div>
    </div>

    <!-- Footer -->
    <ng-container *ngIf="!editingOrder">
      <!-- Channels -->
      <div class="content-channels fx-layout-row">
        <ng-container *ngFor="let channel of channels() | slice: 0 : limitTo">
          <a
            *ngIf="!channel.suspended"
            class="content-channels-chip fx-layout-row"
            target="_blank"
            [href]="channel.href"
            trackClick="Content detail channel chip clicked"
          >
            <mat-icon class="content-channels-icon" [innerHtml]="channel.svg! | safe"></mat-icon>
            <div class="content-channels-name">{{ channel.name }}</div>
          </a>
          <div class="content-channels-chip suspended fx-layout-row" *ngIf="channel.suspended">
            <mat-icon class="content-channels-icon" [innerHtml]="channel.svg! | safe"></mat-icon>
            <div class="content-channels-name">{{ channel.name }}</div>
          </div>
        </ng-container>
        <button
          mat-button
          class="and-more-btn"
          *ngIf="data.item.channels.length > limitTo"
          (click)="showMoreChannels()"
        >
          +{{ data.item.channels.length - limitTo }} more
        </button>
      </div>

      <div class="content-edit-button-container fx-layout-row">
        <!-- Card post label -->
        <div
          class="card-content-label posts"
          [ngStyle]="{ color: data.item.postType?.foreColor, 'background-color': data.item.postType?.backColor }"
          *ngIf="data.item.postType && !data.item.landingPage"
        >
          {{ data.item.postType?.name }}
        </div>
        <div class="fx-flex"></div>

        <div class="pin-container" title="pinned post" *ngIf="item!.pinned">
          <mat-icon class="pin-icon" svgIcon="pin-outline"></mat-icon>
        </div>

        <!-- Statistics -->
        <div class="statistics-container" *ngIf="data.item.contentType !== 'sliders'">
          <div class="stat-item" *ngIf="data.item.modules.includes(7)" matTooltip="CTA Submissions">
            <mat-icon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>bullhorn-outline</title>
                <path
                  d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M15,15.6L13,14H4V10H13L15,8.4V15.6M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z"
                />
              </svg>
            </mat-icon>
            <span class="count">{{ data.item.ctaResponses! | compactNumber }}</span>
          </div>
          <div class="stat-item" *ngIf="data.item.modules.includes(3)" matTooltip="Survey responses">
            <mat-icon>
              <svg viewBox="0 0 24 24">
                <path
                  d="M9 17H7V10H9V17M13 17H11V7H13V17M17 17H15V13H17V17M19 19H5V5H19V19.1M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
                />
              </svg>
            </mat-icon>
            <span class="count">{{ data.item.surveyResponses! | compactNumber }}</span>
          </div>
          <div
            class="stat-item"
            *ngIf="data.item.modules.includes(4) || data.item.contentType === 'events'"
            matTooltip="Attendees"
          >
            <mat-icon svgIcon="ticket-confirmation-outline"></mat-icon>
            <span class="count">{{ data.item.attendees! | compactNumber }}</span>
          </div>
          <div class="stat-item" *ngIf="data.item.modules.includes(5)" matTooltip="Volunteers">
            <mat-icon>
              <svg class="volunteer-icon" viewBox="0 0 24 24">
                <path
                  d="M21.71 8.71C22.96 7.46 22.39 6 21.71 5.29L18.71 2.29C17.45 1.04 16 1.61 15.29 2.29L13.59 4H11C9.1 4 8 5 7.44 6.15L3 10.59V14.59L2.29 15.29C1.04 16.55 1.61 18 2.29 18.71L5.29 21.71C5.83 22.25 6.41 22.45 6.96 22.45C7.67 22.45 8.32 22.1 8.71 21.71L11.41 19H15C16.7 19 17.56 17.94 17.87 16.9C19 16.6 19.62 15.74 19.87 14.9C21.42 14.5 22 13.03 22 12V9H21.41L21.71 8.71M20 12C20 12.45 19.81 13 19 13L18 13L18 14C18 14.45 17.81 15 17 15L16 15L16 16C16 16.45 15.81 17 15 17H10.59L7.31 20.28C7 20.57 6.82 20.4 6.71 20.29L3.72 17.31C3.43 17 3.6 16.82 3.71 16.71L5 15.41V11.41L7 9.41V11C7 12.21 7.8 14 10 14S13 12.21 13 11H20V12M20.29 7.29L18.59 9H11V11C11 11.45 10.81 12 10 12S9 11.45 9 11V8C9 7.54 9.17 6 11 6H14.41L16.69 3.72C17 3.43 17.18 3.6 17.29 3.71L20.28 6.69C20.57 7 20.4 7.18 20.29 7.29Z"
                />
              </svg>
            </mat-icon>
            <span class="count">{{ data.item.volunteers! | compactNumber }}</span>
          </div>
          <div class="stat-item" matTooltip="Likes">
            <mat-icon>
              <svg viewBox="0 0 24 24">
                <title>heart-outline</title>
                <path
                  d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
                />
              </svg>
            </mat-icon>
            <span class="count">{{ data.item.upvotes! | compactNumber }}</span>
          </div>
          <div class="stat-item" matTooltip="Views">
            <mat-icon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>eye-outline</title>
                <path
                  d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"
                />
              </svg>
            </mat-icon>
            <span class="count">{{ data.item.views! | compactNumber }}</span>
          </div>
        </div>
        <!-- Edit button -->
        <button
          mat-stroked-button
          class="content-edit-button"
          [disabled]="!data.item.canEdit"
          (click)="editClicked.emit(item)"
        >
          Edit
        </button>

        <!-- 3 dots menu -->
        <div class="action-buttons">
          <button
            mat-icon-button
            class="content-menu-button"
            [matMenuTriggerFor]="postMenu"
            trackClick="Post menu button clicked"
          >
            <mat-icon class="content-menu-icon">
              <svg class="" viewBox="0 0 24 24">
                <path
                  d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
                />
              </svg>
            </mat-icon>
          </button>
          <mat-menu #postMenu="matMenu" xPosition="before" yPosition="above" class="content-menu">
            <!-- Publish -->
            <button
              mat-menu-item
              (click)="publishClicked.emit(data.item)"
              *ngIf="!data.item.archived"
              [disabled]="isExpired()"
            >
              <mat-icon>
                <svg viewBox="0 0 24 24" *ngIf="!data.item.active">
                  <title>publish</title>
                  <path d="M5,4V6H19V4H5M5,14H9V20H15V14H19L12,7L5,14Z" />
                </svg>
                <svg *ngIf="data.item.active" viewBox="0 0 24 24">
                  <title>publish-off</title>
                  <path
                    d="M20.8 22.7L15 16.9V20H9V14H5L8.6 10.4L1.1 3L2.4 1.7L22.1 21.4L20.8 22.7M19 6V4H7.2L9.2 6H19M17.2 14H19L12 7L11.1 7.9L17.2 14Z"
                  />
                </svg>
              </mat-icon>
              <span>
                @if (isExpired()) {
                  Expired
                } @else if (data.item.active) {
                  Unpublish
                } @else if (data.item.startDate && !startDateExpired()) {
                  Schedule
                } @else {
                  Publish
                }
              </span>
            </button>

            <!-- DELETE -->
            <button mat-menu-item (click)="deleteClicked.emit(data.item)">
              <mat-icon>
                <svg *ngIf="!data.item.archived" viewBox="0 0 24 24">
                  <title>delete-outline</title>
                  <path
                    d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"
                  />
                </svg>
                <svg *ngIf="data.item.archived" viewBox="0 0 24 24">
                  <title>restore</title>
                  <path
                    d="M13,3A9,9 0 0,0 4,12H1L4.89,15.89L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3Z"
                  />
                </svg>
              </mat-icon>
              <span *ngIf="!data.item.archived">Delete</span>
              <span *ngIf="data.item.archived">Restore</span>
            </button>

            <!-- PIN -->
            <button mat-menu-item (click)="pinClicked.emit(data.item)">
              <mat-icon [svgIcon]="item!.pinned ? 'pin-outline' : 'pin'"> </mat-icon>
              <span>{{ item!.pinned ? 'Unpin' : 'Pin' }}</span>
            </button>

            <!-- CLONE -->
            <button mat-menu-item (click)="cloneClicked.emit(data.item)">
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path
                    d="M11,17H4A2,2 0 0,1 2,15V3A2,2 0 0,1 4,1H16V3H4V15H11V13L15,16L11,19V17M19,21V7H8V13H6V7A2,2 0 0,1 8,5H19A2,2 0 0,1 21,7V21A2,2 0 0,1 19,23H8A2,2 0 0,1 6,21V19H8V21H19Z"
                  />
                </svg>
              </mat-icon>
              <span>Clone</span>
            </button>

            <!-- COPY LINK -->
            <button
              mat-menu-item
              (click)="copyContentLinkClicked.emit(data.item)"
              *ngIf="data.item.contentType !== 'sliders'"
            >
              <mat-icon
                ><svg viewBox="0 0 24 24">
                  <path
                    d="M10.59,13.41C11,13.8 11,14.44 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C7.22,12.88 7.22,9.71 9.17,7.76V7.76L12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.59,9.17C9.41,10.34 9.41,12.24 10.59,13.41M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.78,11.12 16.78,14.29 14.83,16.24V16.24L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L13.41,14.83C14.59,13.66 14.59,11.76 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z"
                  />
                </svg>
              </mat-icon>
              <span>Copy link</span>
            </button>

            <!-- VIEWS -->
            <button
              mat-menu-item
              (click)="showViewersClicked.emit(data.item)"
              *ngIf="data.item.contentType !== 'sliders'"
              [disabled]="data.item.views === 0"
            >
              <mat-icon
                ><svg viewBox="0 0 24 24">
                  <path
                    d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"
                  />
                </svg>
              </mat-icon>
              <span>Views</span>
            </button>

            <mat-divider
              *ngIf="
                data.item.modules.includes(7) ||
                data.item.modules.includes(3) ||
                data.item.modules.includes(4) ||
                data.item.modules.includes(5)
              "
            ></mat-divider>

            <!-- CTA RESPONSES -->
            <button
              mat-menu-item
              *ngIf="data.item.modules.includes(7) && data.item.canEdit"
              (click)="showCtaClicked.emit(data.item)"
              [disabled]="data.item.ctaResponses == 0"
            >
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path
                    d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M15,15.6L13,14H4V10H13L15,8.4V15.6M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z"
                  />
                </svg>
              </mat-icon>
              <span>CTA responses</span>
            </button>

            <!-- SURVEY RESULTS -->
            <button
              mat-menu-item
              *ngIf="data.item.modules.includes(3)"
              (click)="showResponsesClicked.emit(data.item)"
              [disabled]="data.item.surveyResponses === 0"
            >
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path
                    d="M9 17H7V10H9V17M13 17H11V7H13V17M17 17H15V13H17V17M19 19H5V5H19V19.1M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
                  />
                </svg>
              </mat-icon>
              <span>Survey results</span>
            </button>

            <!-- TICKETS -->
            <button
              mat-menu-item
              *ngIf="(data.item.modules.includes(4) || data.item.contentType === 'events') && data.item.canEdit"
              (click)="showTicketsClicked.emit(data.item)"
              [disabled]="data.item.attendees === 0"
            >
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path
                    d="M22 10V6C22 4.89 21.1 4 20 4H4C2.9 4 2 4.89 2 6V10C3.11 10 4 10.9 4 12S3.11 14 2 14V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V14C20.9 14 20 13.1 20 12S20.9 10 22 10M20 8.54C18.81 9.23 18 10.53 18 12S18.81 14.77 20 15.46V18H4V15.46C5.19 14.77 6 13.47 6 12C6 10.5 5.2 9.23 4 8.54L4 6H20V8.54M11 15H13V17H11M11 11H13V13H11M11 7H13V9H11Z"
                  />
                </svg>
              </mat-icon>
              <span>Attendees</span>
            </button>

            <!-- VOLUNTEERS -->
            <button
              mat-menu-item
              *ngIf="data.item.modules.includes(5) && data.item.canEdit"
              (click)="showVolunteersClicked.emit(data.item)"
              [disabled]="data.item.volunteers === 0"
            >
              <mat-icon>
                <svg class="volunteer-icon" viewBox="0 0 24 24">
                  <path
                    d="M21.71 8.71C22.96 7.46 22.39 6 21.71 5.29L18.71 2.29C17.45 1.04 16 1.61 15.29 2.29L13.59 4H11C9.1 4 8 5 7.44 6.15L3 10.59V14.59L2.29 15.29C1.04 16.55 1.61 18 2.29 18.71L5.29 21.71C5.83 22.25 6.41 22.45 6.96 22.45C7.67 22.45 8.32 22.1 8.71 21.71L11.41 19H15C16.7 19 17.56 17.94 17.87 16.9C19 16.6 19.62 15.74 19.87 14.9C21.42 14.5 22 13.03 22 12V9H21.41L21.71 8.71M20 12C20 12.45 19.81 13 19 13L18 13L18 14C18 14.45 17.81 15 17 15L16 15L16 16C16 16.45 15.81 17 15 17H10.59L7.31 20.28C7 20.57 6.82 20.4 6.71 20.29L3.72 17.31C3.43 17 3.6 16.82 3.71 16.71L5 15.41V11.41L7 9.41V11C7 12.21 7.8 14 10 14S13 12.21 13 11H20V12M20.29 7.29L18.59 9H11V11C11 11.45 10.81 12 10 12S9 11.45 9 11V8C9 7.54 9.17 6 11 6H14.41L16.69 3.72C17 3.43 17.18 3.6 17.29 3.71L20.28 6.69C20.57 7 20.4 7.18 20.29 7.29Z"
                  />
                </svg>
              </mat-icon>
              <span>Volunteers</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-content></ng-content>
