import { Pipe, PipeTransform } from '@angular/core';

export function compactNumber(value: number, locale = 'en'): string {
  const formatter = Intl.NumberFormat(locale, { notation: 'compact' });
  return formatter.format(value);
}

@Pipe({
  name: 'compactNumber',
  standalone: true
})
export class CompactNumberPipe implements PipeTransform {
  transform(value: number): string {
    return compactNumber(value);
  }
}
