import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
  signal
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { LoginDialogService } from '@ih/authentication';
import { ChoosePlanDialogService } from '@ih/choose-plan-dialog';
import { EVENT_DATE_CARD_FORMAT, EVENT_DATE_FORMAT } from '@ih/constants';
import { InProgressDirective, TrackClickDirective } from '@ih/directives';
import { EmojiPipe } from '@ih/emoji';
import { ChannelPermission, ContentState, ContentTypes } from '@ih/enums';
import { ImageComponent, StockPhotoComponent } from '@ih/image';
import { ContentItem, PostType } from '@ih/interfaces';
import { CharactersPipe, DurationPipe, HighlightPipe, HtmlEncodeSimplePipe, LbToBrPipe } from '@ih/pipes';
import { PostEditorService } from '@ih/post-editor';
import { SafePipe } from '@ih/safe-pipe';
import {
  AuthService,
  ChannelService,
  ContentService,
  LazySnackBarService,
  PostTypeService,
  SecurityService
} from '@ih/services';
import { TimeAgoComponent } from '@ih/time';
import { getAlphaClass } from '@ih/utilities';
import { BehaviorSubject, Subject, combineLatest, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { ContentCardGhostComponent } from '../content-card-ghost/content-card-ghost.component';
import { HeartComponent } from '../heart/heart.component';

@Component({
  standalone: true,
  selector: 'ih-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,

    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,

    CharactersPipe,
    DurationPipe,
    EmojiPipe,
    HighlightPipe,
    HtmlEncodeSimplePipe,
    ImageComponent,
    InProgressDirective,
    LbToBrPipe,

    SafePipe,

    StockPhotoComponent,
    TimeAgoComponent,
    TrackClickDirective,

    ContentCardGhostComponent,
    HeartComponent
  ]
})
export class ContentCardComponent implements OnInit, OnChanges, OnDestroy {
  private el = inject(ElementRef);
  private loginDialog = inject(LoginDialogService);
  private auth = inject(AuthService);
  private postTypeService = inject(PostTypeService);
  private router = inject(Router);
  private cd = inject(ChangeDetectorRef);
  private content = inject(ContentService);
  private channelService = inject(ChannelService);
  private snackbar = inject(LazySnackBarService);
  private security = inject(SecurityService);
  private choosePlanDialog = inject(ChoosePlanDialogService);
  private postEditor = inject(PostEditorService);

  @HostBinding('id') contentIdHash!: string;
  @HostBinding('class.ih-content-card') hostClass = true;
  @HostBinding('class.mat-card') matCardClass = true;
  @HostBinding('class.init') initialized = false;
  @HostBinding('style.min-height.px') cardHeight = 60;

  @ViewChild('cardImage') cardImage!: ImageComponent;

  EVENT_DATE_FORMAT = EVENT_DATE_FORMAT;
  EVENT_DATE_CARD_FORMAT = EVENT_DATE_CARD_FORMAT;

  aspectRatio$ = new BehaviorSubject<number | undefined>(undefined);

  joiningChannel = signal(false);

  canEdit = signal(false);
  canPublish = signal(false);

  private destroy$ = new Subject<void>();
  private itemChange$ = new BehaviorSubject<ContentItem | null>(null);

  private _item!: ContentItem;
  @Input()
  get item(): ContentItem {
    return this._item;
  }

  set item(value: ContentItem) {
    this._item = value;

    this.showMore = value.titleExpanded || false;

    this.contentIdHash = `content_${value.contentType}_${value.contentId}`;

    if (!value.mediaType || value.mediaType === 'none') {
      this._item = {
        ...value,
        mediaType: 'alpha'
      };
    }

    switch (this._item.mediaType) {
      case 'alpha':
        this.showAlpha = true;
        break;
      case 'image':
      case 'link':
        this.showImage = value.image?.url != null;
        break;
      case 'video':
        this.showImage = value.image?.url != null;
        break;
      case 'custom':
        this.showImage = value.image?.url != null;
        break;
    }

    if (this.showImage) {
      // calculate the aspect ratio of the image
      let aspectRatio = 16 / 9;
      if (value.image?.cropData?.w && value.image?.cropData?.h) {
        aspectRatio = value.image.cropData.w / value.image.cropData.h;
      }
      this.aspectRatio$.next(aspectRatio);
    }

    const canEdit = value.channels.some(
      (channelId: number) =>
        this.security.canChannel(ChannelPermission.PostsCreateEdit, channelId) ||
        (this.security.canChannel(ChannelPermission.PostsEditOther, channelId) &&
          value.authorId !== this.auth.currentUser$.value?.campaign_user_id)
    );
    this.canEdit.set(canEdit);
    const canPublish = value.channels.some((channelId: number) =>
      this.security.canChannel(ChannelPermission.PostsPublishScheduleArchive, channelId)
    );
    this.canPublish.set(canPublish);

    this.itemChange$.next(this._item);

    // wait for dom to render
    setTimeout(() => {
      this.el.nativeElement.querySelector('ih-content-card-ghost')?.remove();
    });
  }

  @Input() alphaMinHeight!: string;
  @Input() isSearchResult = false;

  commentText!: string;
  itemUrl!: string;
  showImage = false;
  showAlpha = false;
  showMore = false;
  alphaClass!: string;
  itemLabel: string | undefined;
  postType: PostType | null = null;
  endDate!: string;
  started = false;
  concluded = false;
  hasImage = false;

  shouldWrap = false;

  ContentTypes = ContentTypes;

  constructor() {}

  ngOnInit(): void {
    this.commentText = this.item.commentCount === 1 ? 'comment' : 'comments';

    // if this is an event, we may need to update the date if the start date passes
    if (this.item.contentType === ContentTypes.Event && this.item.startDate! > new Date()) {
      // force change detection to run when the start date passes
      timer(this.item.eventStartDate!.getTime() - new Date().getTime())
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.cd.markForCheck();
        });
    }

    // wait for postType or contentItem changes
    combineLatest([this.postTypeService.indexedPostTypes$, this.itemChange$])
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(([postTypes, contentItem]) => {
        // not sure why, but we don't seem to need a timeout here, it causes jank

        // init postType if applicable
        this.postType = null;
        if (contentItem!.postTypeId && Object.values(postTypes).length > 0) {
          this.postType = postTypes[contentItem!.postTypeId];
        }

        this.itemUrl =
          (contentItem!.mediaType == 'link' && contentItem!.cardLink) ||
          (contentItem!.itemUrl
            ? contentItem!.itemUrl
            : `/${contentItem!.contentType}/${contentItem!.contentId}${
                contentItem!.slug ? '/' + contentItem!.slug : ''
              }`);

        this.alphaClass = `alpha-${getAlphaClass(contentItem!.title || '💬')}`;

        this.itemLabel = contentItem!.contentTypeAlias;

        this.concluded = contentItem!.eventEndDate! < new Date();
        this.started = contentItem!.eventStartDate! < new Date();

        this.cd.markForCheck();
      });
  }

  ngOnChanges(): void {
    this.itemChange$.next(this.item);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  changeFilter(): void {
    if (this.item.contentType === 'events') {
      this.router.navigate(['/events']);
      return;
    }

    this.content.filterChangeRequest$.next(
      this.item.contentType === 'posts' ? this.item.postTypeId!.toString() : this.item.contentType
    );
  }

  clicked($event: Event): void {
    // if the itemUrl is absolute then we don't need to do anything
    if (this.itemUrl.startsWith('http')) {
      return;
    }

    // if the post is locked then we need to trigger the channel join button
    if (this.item.locked) {
      $event.preventDefault();

      this.joiningChannel.set(true);

      this.channelService
        .joinChannel$(this.item.channels[0])
        .pipe(
          catchError((err, caught) => {
            switch (err.message) {
              case 'NOT_LOGGED_IN':
                this.loginDialog.open();
                return of(null);
              case 'REQUEST_ALREADY_SENT':
                this.snackbar.open('You have already requested access to this channel');
                return of(null);
              case 'SHOW_PRICING_TABLE':
                this.choosePlanDialog.showPricingTable(this.item.channels[0]).subscribe();
                return of(null);
              default:
                return this.channelService.handleJoinOrLeaveError(err, caught);
            }
          })
        )
        .subscribe(() => {
          this.joiningChannel.set(false);
        });

      return;
    }

    // add the hash to the url so if we go back we know where to scroll
    this.router.navigate(window.location.pathname.split('/').slice(1), {
      fragment: this.contentIdHash,
      replaceUrl: true
    });

    $event.preventDefault();

    this.router.navigateByUrl(this.itemUrl, {
      state: {
        placeholderImage: this.cardImage?.largeSrc()
      }
    });
  }

  toggleShowMore($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.showMore = !this.showMore;
    // update the content cache
    this.content.partialContentUpdate(this.item.contentType, this.item.contentId, {
      titleExpanded: this.showMore
    });
  }

  swallowClick($event: Event): void {
    this.shouldWrap = !this.shouldWrap;
    $event.preventDefault();
    $event.stopPropagation();
    this.cd.markForCheck();
  }

  click(event: Event): void {
    event.stopPropagation();
  }

  vote(): void {
    if (!this.auth.isAuthenticated()) {
      this.loginDialog.open();
      return;
    }

    this.content
      .updateVote(this.item.contentType, this.item.contentId)
      .pipe(
        catchError((err) => {
          if (err === 'userNoLoggedIn') {
            this.loginDialog.open();
          }

          return of(null);
        })
      )
      .subscribe();
  }

  edit(): void {
    this.channelService.channels$.pipe(take(1)).subscribe((channels) => {
      this.postEditor.open({
        postId: this.item.contentId,
        landingPage: false,
        channels: this.item.channels.map((channelId) => channels[channelId]),
        instantPublish: true
      });
    });
  }

  unpublish(): void {
    this.snackbar.open('Please wait while we unpublish this post...', undefined, {
      duration: 60000
    });
    this.content
      .updateContentItemState$(this.item.contentType as ContentTypes, this.item.contentId, ContentState.Draft)
      .pipe(
        catchError((err) => {
          this.snackbar.open('An error occurred while trying to unpublish this post');
          throw err;
        })
      )
      .subscribe(() => {
        this.snackbar.open('Post unpublished');
      });
  }

  togglePin(): void {
    this.content
      .setPostPinned$(this.item.contentId, !this.item.pinned)
      .pipe(
        catchError((err) => {
          this.snackbar.open('An error occurred while trying to update the pinned status of this post');
          throw err;
        })
      )
      .subscribe();
  }
}
