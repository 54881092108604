<div *ngIf="location$ | async as location">
  <div class="location-header">
    <div class="subheader primary-background-color primary-font-color">Location</div>
  </div>

  <ih-location-detail
    [location]="location.latLng"
    [zoom]="location.zoom"
    [mapTypeId]="location.mapTypeId"
  ></ih-location-detail>
  <div class="btn-view-in-google-maps-container fx-layout-row fx-layout-align-center">
    <a
      mat-button
      class="btn-view-in-google-maps"
      href="https://www.google.com/maps/dir/Current+Location/{{ location.latLng.lat }},{{ location.latLng.lng }}"
      target="_blank"
    >
      VIEW IN GOOGLE MAPS
    </a>
  </div>
</div>
