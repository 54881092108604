<div class="col-sm-12 mt alert alert-warning">
  <div #ref>
    <ng-content></ng-content>
  </div>
  <ng-container *ngIf="!ref.hasChildNodes()">
    <h4 class="text-center">Oh no! There was a problem getting the latest content.</h4>
    <h4 class="text-center">You may be offline. Please check your connection and try again.</h4>
  </ng-container>
  <button mat-raised-button (click)="reconnect()">Reconnect</button>
</div>
