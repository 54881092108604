import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  DestroyRef,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContentItem } from '@ih/interfaces';
import { BehaviorSubject, take } from 'rxjs';

@Component({
  standalone: true,
  selector: 'ih-item-wrapper',
  template: ` <ng-container #container></ng-container> `,
  styles: [':host { display: block; contain:layout; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgIf, NgTemplateOutlet]
})
export class ItemWrapperComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);
  @HostBinding('style.minHeight.px') elementHeight = 0;
  @ViewChild('container', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;
  @ContentChild(TemplateRef, { static: true }) template!: TemplateRef<any>;

  private embeddedViewRef: EmbeddedViewRef<any> | null = null;

  el = inject(ElementRef);
  private ngZone = inject(NgZone);

  @Input() item!: ContentItem;

  private visible$ = new BehaviorSubject<boolean>(false);

  @Input()
  set visible(val: boolean) {
    this.visible$.next(val);
  }

  @Input()
  set estimatedHeight(val: number) {
    this.elementHeight = val;
  }

  @Output() itemHeightChanged = new EventEmitter<{ wrapper: ItemWrapperComponent; currentHeight: number }>();

  private resizeObserver!: ResizeObserver;

  ngOnInit() {
    this.visible$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((visible) => {
      if (visible) {
        this.showComponent();
      } else {
        this.hideComponent();
      }
    });
  }

  ngOnDestroy() {
    if (this.embeddedViewRef) {
      this.embeddedViewRef.destroy();
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  private showComponent() {
    if (!this.embeddedViewRef) {
      this.embeddedViewRef = this.container.createEmbeddedView(this.template, { $implicit: this.item });
    } else {
      this.container.insert(this.embeddedViewRef);
    }
    // reset the element height to auto to get the correct height
    this.el.nativeElement.style.height = 'auto';

    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      this.updateHeight();
      this.createResizeObserver();
    });
  }

  private hideComponent() {
    if (this.embeddedViewRef) {
      // get the current height before detaching
      const elementHeight = this.el.nativeElement.offsetHeight;
      this.el.nativeElement.style.height = `${elementHeight}px`;

      this.container.detach();
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null!;
    }
  }

  private createResizeObserver() {
    if (this.resizeObserver || !('ResizeObserver' in window)) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      this.resizeObserver = new ResizeObserver((entries) => {
        if (entries.length > 0 && this.visible$.value) {
          const newHeight = entries[0].contentRect.height;
          if (newHeight !== this.elementHeight) {
            this.ngZone.run(() => {
              this.elementHeight = newHeight;
              this.itemHeightChanged.emit({ wrapper: this, currentHeight: newHeight });
            });
          }
        }
      });

      this.resizeObserver.observe(this.el.nativeElement);
    });
  }

  private updateHeight() {
    const newHeight = this.el.nativeElement.offsetHeight;
    if (newHeight !== this.elementHeight) {
      this.elementHeight = newHeight;
      this.itemHeightChanged.emit({ wrapper: this, currentHeight: newHeight });
    }
  }
}
