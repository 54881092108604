import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { DEFAULT_GMAP_OPTIONS } from '@ih/constants';
import { GmapsService } from '@ih/services';
import { BehaviorSubject } from 'rxjs';

@Component({
  standalone: true,
  selector: 'ih-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, GoogleMapsModule]
})
export class LocationDetailComponent {
  @HostBinding('class.ih-location-detail') hostClass = true;

  private gmaps = inject(GmapsService);

  isLoaded$ = this.gmaps.isLoaded$;
  mapOptions$ = new BehaviorSubject<google.maps.MapOptions>(DEFAULT_GMAP_OPTIONS);
  position$ = new BehaviorSubject<google.maps.LatLng | null>(null);

  disabled$ = new BehaviorSubject<boolean>(false);

  @Input() set zoom(value: number) {
    if (this.mapOptions$.value.zoom === value) {
      return;
    }

    this.mapOptions$.next({
      ...this.mapOptions$.value,
      ...{
        zoom: value
      }
    });
  }

  @Input()
  set location(value: { lat: number; lng: number }) {
    if (this.mapOptions$.value.center!.lat === value.lat && this.mapOptions$.value.center!.lng === value.lng) {
      return;
    }

    const latLng = { lat: value.lat, lng: value.lng } as unknown as google.maps.LatLng;
    this.mapOptions$.next({
      ...this.mapOptions$.value,

      center: value
    });
    this.position$.next(latLng);
  }

  @Input()
  set mapTypeId(value: string) {
    if (this.mapOptions$.value.mapTypeId === value) {
      return;
    }

    this.mapOptions$.next({
      ...this.mapOptions$.value,

      mapTypeId: value
    });
  }

  @Input()
  set disabled(value: boolean) {
    this.disabled$.next(value);
  }
}
